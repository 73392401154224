import { TouchableOpacity, StyleProp, ViewStyle, View } from "react-native";

import { tailwind } from "../styles";

import { Paragraph } from ".";

// TODO: For web a11y, we need to accept an `href`. When provided, should result in an anchor tag.
export type Crumb = {
  title: string;
  onPress?: () => void;
};

export type Props = {
  crumbs: Array<Crumb>;
  style?: StyleProp<ViewStyle>;
};

export default function Breadcrumb({ crumbs, style }: Props) {
  return (
    <View style={[tailwind("flex-row"), style]}>
      {crumbs.map(({ title, onPress }, index) => {
        return (
          <View key={title} style={tailwind("flex-row")}>
            {onPress ? (
              <TouchableOpacity onPress={onPress}>
                <Paragraph level={2} weight={index === crumbs.length - 1 ? "bold" : "normal"}>
                  {title}
                </Paragraph>
              </TouchableOpacity>
            ) : (
              <Paragraph level={2} weight={index === crumbs.length - 1 ? "bold" : "normal"}>
                {title}
              </Paragraph>
            )}
            {index !== crumbs.length - 1 && <Paragraph level={2}> {">"} </Paragraph>}
          </View>
        );
      })}
    </View>
  );
}
