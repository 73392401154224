import * as React from "react";
import Svg, { Path } from "svgs";

import colors from "../colors.json";

import { IconProps, DISABLED_COLOR } from ".";

export default function EnrolledMember(props: IconProps) {
  const color = props.disabled ? DISABLED_COLOR : props.color;
  return (
    <Svg width={props.size} height={props.size} viewBox="0 0 20 20" fill="none">
      <Path
        d="M6.40778 6.04256C6.90644 6.04256 7.3939 5.89469 7.80852 5.61764C8.22315 5.3406 8.5463 4.94683 8.73713 4.48613C8.92796 4.02543 8.97789 3.51848 8.88061 3.0294C8.78333 2.54032 8.5432 2.09107 8.19059 1.73847C7.83798 1.38586 7.38873 1.14573 6.89965 1.04845C6.41057 0.951163 5.90363 1.00109 5.44292 1.19192C4.98222 1.38275 4.58845 1.70591 4.31141 2.12053C4.03437 2.53515 3.8865 3.02262 3.8865 3.52128C3.8865 3.85238 3.95171 4.18023 4.07842 4.48613C4.20513 4.79203 4.39084 5.06997 4.62496 5.30409C5.09779 5.77692 5.73909 6.04256 6.40778 6.04256ZM6.40778 2.00851C6.70697 2.00851 6.99945 2.09723 7.24822 2.26346C7.497 2.42968 7.69089 2.66595 7.80539 2.94237C7.91989 3.21879 7.94985 3.52296 7.89148 3.81641C7.83311 4.10985 7.68903 4.3794 7.47746 4.59097C7.2659 4.80253 6.99635 4.94661 6.7029 5.00498C6.40945 5.06335 6.10529 5.03339 5.82886 4.91889C5.55244 4.8044 5.31618 4.6105 5.14996 4.36173C4.98373 4.11295 4.89501 3.82048 4.89501 3.52128C4.89501 3.12007 5.05439 2.73529 5.33809 2.45159C5.62179 2.16789 6.00657 2.00851 6.40778 2.00851Z"
        fill={color || colors.gray[800]}
      />
      <Path
        d="M4.98935 17.73L4.49418 12.2649C4.48538 12.167 4.44814 12.0737 4.38704 11.9967C4.32594 11.9196 4.24364 11.8621 4.15027 11.8312L3.01772 11.4571L3.09436 8.18447C4.13335 7.70559 5.26379 7.45761 6.40782 7.45761C7.55186 7.45761 8.6823 7.70559 9.72129 8.18447L9.79894 11.4571L8.66639 11.8312C8.57321 11.8623 8.49113 11.9199 8.43021 11.9969C8.3693 12.0739 8.33221 12.1671 8.32349 12.2649L7.8263 17.73C7.82033 17.796 7.82742 17.8625 7.84715 17.9257C7.86688 17.9889 7.89887 18.0476 7.9413 18.0985C7.98372 18.1493 8.03575 18.1913 8.0944 18.2221C8.15306 18.2528 8.2172 18.2717 8.28315 18.2776C8.41567 18.2865 8.54652 18.244 8.6485 18.1589C8.75049 18.0739 8.81577 17.9528 8.83077 17.8208L9.29771 12.6855L10.4706 12.2962C10.5732 12.2623 10.6621 12.1964 10.7244 12.1081C10.7867 12.0199 10.819 11.914 10.8165 11.806L10.7217 7.84359C10.7194 7.75006 10.6911 7.65901 10.64 7.58065C10.5889 7.50228 10.517 7.43968 10.4323 7.39985C9.1815 6.77923 7.80412 6.4563 6.40782 6.4563C5.01153 6.4563 3.63415 6.77923 2.38336 7.39985C2.2987 7.43968 2.22678 7.50228 2.17567 7.58065C2.12455 7.65901 2.09625 7.75006 2.09392 7.84359L2.00013 11.806C1.9977 11.914 2.03 12.0199 2.09227 12.1081C2.15454 12.1964 2.2435 12.2623 2.34605 12.2962L3.51895 12.6844L3.98488 17.8198C3.99084 17.886 4.00978 17.9504 4.04063 18.0093C4.07148 18.0682 4.11362 18.1204 4.16466 18.1631C4.2157 18.2057 4.27463 18.2378 4.33809 18.2576C4.40155 18.2775 4.4683 18.2846 4.53452 18.2787C4.60074 18.2727 4.66513 18.2538 4.72403 18.2229C4.78293 18.1921 4.83518 18.1499 4.87779 18.0989C4.9204 18.0478 4.95254 17.9889 4.97237 17.9254C4.99221 17.862 4.99935 17.7952 4.99339 17.729L4.98935 17.73Z"
        fill={color || colors.gray[800]}
      />
      <Path
        d="M16.1041 7.17757L14.5821 9.03857L13.8821 8.17757C13.7975 8.07723 13.6768 8.01421 13.546 8.00213C13.4153 7.99004 13.2851 8.02985 13.1835 8.11296C13.0818 8.19607 13.017 8.31581 13.0029 8.44633C12.9888 8.57686 13.0266 8.70768 13.1081 8.81057L14.1911 10.1436C14.238 10.2009 14.2971 10.2471 14.364 10.2788C14.4309 10.3105 14.5041 10.327 14.5781 10.327C14.6522 10.327 14.7254 10.3105 14.7923 10.2788C14.8592 10.2471 14.9183 10.2009 14.9651 10.1436L16.8781 7.81057C16.9597 7.70768 16.9975 7.57686 16.9834 7.44633C16.9693 7.31581 16.9045 7.19607 16.8028 7.11296C16.7012 7.02985 16.571 6.99004 16.4403 7.00213C16.3095 7.01421 16.1888 7.07723 16.1041 7.17757Z"
        fill={color || colors.gray[800]}
      />
    </Svg>
  );
}
