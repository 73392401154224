import { useEffect, useState, useRef } from "react";
import { Platform } from "react-native";

export function useHover<T = any>() {
  const [hovered, setHovered] = useState<boolean>(false);
  const ref = useRef<T | null>(null);

  const onMouseEnter = () => setHovered(true);
  const onMouseLeave = () => setHovered(false);

  useEffect(() => {
    if (Platform.OS !== "web") {
      // Hover is only available on web
      return;
    }
    const node = ref && ref.current;
    if (node) {
      /* eslint-disable @typescript-eslint/no-explicit-any */
      (node as any).addEventListener("mouseenter", onMouseEnter);
      (node as any).addEventListener("mouseleave", onMouseLeave);
      return () => {
        (node as any).removeEventListener("mouseenter", onMouseEnter);
        (node as any).removeEventListener("mouseleave", onMouseLeave);
      };
    }
    /* eslint-enable @typescript-eslint/no-explicit-any */
  }, [ref]);

  // Returns ref node and hovered state
  // TODO: Make this change in the enterprise copy.
  return [ref, hovered] as const;
}
