import { TRUSTED_DEVICE_CODES_STORAGE_KEY } from "src/auth/constants";

// TODO: ui to forget trusted devices
// TODO: Refactor into a custom hook
export const getTrustedDeviceCode = (email: string): string | undefined => {
  const codeLookup = localStorage.getItem(TRUSTED_DEVICE_CODES_STORAGE_KEY);
  if (!codeLookup) return;
  return JSON.parse(codeLookup)[email];
};

export const setTrustedDeviceCode = (email: string, code: string): void => {
  const codeLookup = localStorage.getItem(TRUSTED_DEVICE_CODES_STORAGE_KEY);
  const parsedCodeLookup: Record<string, string> = codeLookup ? JSON.parse(codeLookup) : {};
  localStorage.setItem(
    TRUSTED_DEVICE_CODES_STORAGE_KEY,
    JSON.stringify({ ...parsedCodeLookup, [email]: code })
  );
};
