import { LegacyRef } from "react";
import {
  StyleProp,
  ViewStyle,
  TouchableOpacity,
  View, // TODO: added for encapsulating checkbox and label
} from "react-native";

import { useHover } from "src/foundations/hooks/useHover";
import { Icon, Paragraph } from "src/foundations/ui";

import { tailwind } from "../styles";

const DEFAULT_SIZE = 20;

type props = {
  /**
   * TODO: added required label to display text next to checkbox.
   */
  label: string;
  /**
   * TODO: added optional visible label
   * responsible for hiding or display label
   */
  visibleLabel?: boolean;
  style?: StyleProp<ViewStyle>;
  checked?: boolean;
  disabled?: boolean;
  onChange?: () => void;
  size?: number; // TODO: talk about making standard size i.e sm md
  accessibilityLabel?: string;
};
export default function Checkbox({
  style,
  checked,
  disabled,
  onChange,
  size = DEFAULT_SIZE,
  // TODO: added label from props
  label,
  // TODO: added visibleLabel from props and default to true
  visibleLabel = true,
  accessibilityLabel,
}: props) {
  const [ref, hovered] = useHover();

  const getStyle = () => {
    if (checked) {
      return tailwind("bg-green-light rounded-sm items-center justify-center");
    }
    if (!disabled && hovered) {
      return tailwind(
        "bg-green-lighter_50 border border-gray-200 rounded-sm items-center justify-center"
      );
    }
    return tailwind("bg-white border border-gray-200 rounded-sm items-center justify-center");
  };

  return (
    // TODO: moved inner touchable opacity to root level
    <TouchableOpacity
      ref={ref as LegacyRef<TouchableOpacity>}
      // TODO: moved the style that was attached to the TouchableOpacity to the first child of the View below
      style={style}
      onPress={onChange}
      accessible={true}
      // TODO: update accessibilityLabel to default to the required label if not provided
      accessibilityLabel={accessibilityLabel ?? label}
      accessibilityHint={checked === true ? "Unchecks the checkbox" : "Checks the checkbox"}
      accessibilityRole="checkbox"
      accessibilityState={{ checked }}
      disabled={disabled}
    >
      <View style={tailwind("flex flex-row flex-nowrap items-center")}>
        {/**
         * TODO: Converted what was a inner TouchableOpacity into a simple View
         * The TouchableOpacity was moved to highest level to encompass the label
         * as well.
         */}
        <View
          style={[
            getStyle(),
            { height: size || DEFAULT_SIZE, width: size },
            tailwind(visibleLabel ? "mr-2" : ""),
          ]}
        >
          {checked && <Icon size={size * 0.65} name="check" color="white" />}
        </View>

        {/* TODO: Added paragraph to display label */}
        {visibleLabel ? (
          <Paragraph
            // TODO: tie the size of the label with the size of the checkbox
            level={2}
            children={label}
          />
        ) : null}
      </View>
    </TouchableOpacity>
  );
}
