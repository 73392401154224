import React, { useRef } from "react";
import {
  Text,
  TouchableOpacity,
  StyleSheet,
  StyleProp,
  ViewStyle,
  GestureResponderEvent,
  View,
} from "react-native";

// TODO: Use Popover for menu items so the option can be visible when displayed out in the wild.
import Popover from "src/ui/components/Popover";

import { tailwind } from "../../styles";
import LoadingSpinner from "../LoadingSpinner";
// TODO: removed need for useDropdownCloseHandlers Popper handles escape
import {
  Option,
  /*, useDropdownCloseHandlers*/
} from "../Select/utils";
import Colors from "../colors.json";
import Icon from "../icons";

export type Action = {
  label: string;
  callback: () => void;
  // TODO: add disabled options
  disabled?: boolean;
};

type Props = {
  label: string;
  onPress?: (event: GestureResponderEvent) => void;
  style?: StyleProp<ViewStyle>;
  testID?: string;
  disabled?: boolean;
  loading?: boolean;
  // TODO: make isOpen optional so users can use as a uncontrolled component
  isOpen: boolean;
  // TODO: make onClose optional so users can use as a uncontrolled component
  onClose: () => void;
  // TODO: make onOpen optional so users can use as a uncontrolled component
  onOpen: () => void;
  items: Array<Action>;
  // temporary - this will lock the popup to the right side of the button
  // (for use near the right side of the window)
  right?: boolean;
};

export default function DropdownButton(props: Props) {
  // Setup handlers for closing dropdown on offclick or escape
  const buttonRef = useRef<View>(null);
  // TODO: removed use of useDropdownCloseHandlers below as it is handled by the Popover
  // useDropdownCloseHandlers(selectRef, () => {
  //   if (isOpen) onClose();
  // });

  const { isOpen, onClose, onOpen } = props;
  const onClickButton = () => (isOpen ? onClose() : onOpen());

  return (
    <View style={[tailwind("items-start z-dropdown"), props.style]}>
      {/* buttonRef needs to be passed to this view, since this
      is the one that doesn't automatically stretch to fill row space */}
      <View style={tailwind("items-start")} ref={buttonRef}>
        <TouchableOpacity
          onPress={onClickButton}
          disabled={props.disabled}
          testID={props.testID}
          style={[
            tailwind(
              `rounded-full self-center justify-center ${
                props.disabled ? "border-0 bg-gray-100" : "bg-gray-800"
              }`
            ),
            styles.container,
            props.style,
          ]}
          accessibilityRole="button"
          accessibilityLabel={props.label}
          accessibilityHint="Opens a dropdown list of actions"
          accessibilityState={{ expanded: isOpen }}
        >
          <View style={tailwind("flex-row items-center justify-center")}>
            {props.loading && (
              <View style={tailwind("px-6 py-1")}>
                <LoadingSpinner size={24} />
              </View>
            )}
            {!props.loading && (
              <>
                {/* TODO: should be updated to use paragraph so we have consistent text line heights and such */}
                <Text
                  accessibilityRole="label"
                  style={[styles.text, props.disabled && styles.textDisabled]}
                >
                  {props.label}
                </Text>
                {/* TODO: add animation for rotation to chevron icon */}
                <View
                  style={[
                    tailwind("ml-3"),
                    { transform: [{ rotate: isOpen ? "180deg" : "0deg" }] },
                  ]}
                >
                  <Icon name="chevron-down" size={10} color={Colors.gray[800]} />
                </View>
              </>
            )}
          </View>
        </TouchableOpacity>
        {/* TODO: Changed to display in a Popover instead of an absolutely-positioned div) */}
        <Popover
          anchor={buttonRef}
          nativeID="dropdown-button-popover"
          isOpen={isOpen && props.items.length > 0}
          onClose={props.onClose}
          placement="bottom right"
        >
          {/* TODO: ensure that the default styling of popover meets expectations */}
          <View
            testID="action-button-dropdown"
            style={[
              // TODO: - get rid of this right-0 style with future popover logic
              // TODO: Some of these styles are unnecessary when rendered inside a Popover
              // "bg-white rounded-lg shadow shadow-size-s flex-1 items-center flex-col p-0 py-2 top-full right-0 mt-2"
              tailwind(`rounded-lg flex-1 items-center flex-col`),
              { maxHeight: 550, width: 233 },
            ]}
          >
            {/* TODO: inline item so easier to view markup */}
            {props.items.map(({ callback, label, disabled }) => (
              <TouchableOpacity
                // TODO: updated onPress to invoke onClose when item pressed
                onPress={() => {
                  onClose();
                  callback();
                }}
                // TODO: add disabled
                disabled={disabled}
                style={tailwind("w-full")}
                key={label}
              >
                <Option label={label} value={label} />
              </TouchableOpacity>
            ))}
          </View>
        </Popover>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    // TODO: changed from 20 to 16 per design mock
    paddingHorizontal: 16,
    // TODO: changed from 10 to 11 per design mock to account for 12 px padding minus the border width
    paddingVertical: 11,
    backgroundColor: Colors.gray[100],
    borderColor: Colors.gray[800],
    // TODO: change width from 2 to 1 per design mock
    borderWidth: 1,
  },
  text: {
    fontFamily: "Inter-semibold",
    fontSize: 14,
    // TODO: changed 32 to 16 per design mock,
    lineHeight: 16,
    color: Colors.gray[800],
  },
  textDisabled: {
    color: Colors.gray[400],
  },
});
