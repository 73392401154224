import * as React from "react";
import Svg, { Path, Circle } from "svgs";

import colors from "../colors.json";

import { IconProps, DISABLED_COLOR } from ".";

export default function SettingsIcon(props: IconProps) {
  const color = props.disabled ? DISABLED_COLOR : props.color;
  return (
    <Svg width={props.size} height={props.size} viewBox="0 0 64 64" fill="none">
      <Circle
        opacity="0.4"
        cx="32"
        cy="32"
        r="32"
        fill={props.clearBackground ? "transparent" : color || colors.green.lighter}
      />
      <Path
        d="M32 11.4238C27.8636 11.427 23.8975 13.0716 20.9726 15.9965C18.0477 18.9214 16.4031 22.8874 16.4 27.0238C16.4 36.4358 25.786 47.3758 29.822 51.6398C30.1006 51.9365 30.4371 52.1729 30.8107 52.3345C31.1842 52.4962 31.5869 52.5795 31.994 52.5795C32.401 52.5795 32.8037 52.4962 33.1772 52.3345C33.5508 52.1729 33.8873 51.9365 34.166 51.6398C38.2 47.3718 47.6 36.4318 47.6 27.0198C47.5957 22.8841 45.9507 18.9191 43.0259 15.9951C40.1011 13.071 36.1357 11.427 32 11.4238ZM32.718 50.2618C32.6247 50.358 32.5131 50.4345 32.3898 50.4867C32.2665 50.5389 32.1339 50.5658 32 50.5658C31.866 50.5658 31.7335 50.5389 31.6101 50.4867C31.4868 50.4345 31.3752 50.358 31.282 50.2618C28.26 47.0698 18.4 35.9578 18.4 27.0198C18.3459 25.2007 18.6575 23.3891 19.3164 21.6926C19.9752 19.9961 20.9679 18.449 22.2355 17.1431C23.5032 15.8372 25.0201 14.7991 26.6963 14.0901C28.3725 13.3812 30.174 13.0159 31.994 13.0159C33.8139 13.0159 35.6154 13.3812 37.2916 14.0901C38.9678 14.7991 40.4847 15.8372 41.7524 17.1431C43.02 18.449 44.0127 19.9961 44.6716 21.6926C45.3304 23.3891 45.642 25.2007 45.588 27.0198C45.6 35.9578 35.74 47.0698 32.718 50.2618Z"
        fill={color || colors.green.dark}
      />
      <Path
        d="M39 24H36V21C36 20.7348 35.8946 20.4804 35.7071 20.2929C35.5196 20.1054 35.2652 20 35 20H29C28.7348 20 28.4804 20.1054 28.2929 20.2929C28.1054 20.4804 28 20.7348 28 21V24H25C24.7348 24 24.4804 24.1054 24.2929 24.2929C24.1054 24.4804 24 24.7348 24 25V31C24 31.2652 24.1054 31.5196 24.2929 31.7071C24.4804 31.8946 24.7348 32 25 32H28V35C28 35.2652 28.1054 35.5196 28.2929 35.7071C28.4804 35.8946 28.7348 36 29 36H35C35.2652 36 35.5196 35.8946 35.7071 35.7071C35.8946 35.5196 36 35.2652 36 35V32H39C39.2652 32 39.5196 31.8946 39.7071 31.7071C39.8946 31.5196 40 31.2652 40 31V25C40 24.7348 39.8946 24.4804 39.7071 24.2929C39.5196 24.1054 39.2652 24 39 24ZM38 30H35C34.7348 30 34.4804 30.1054 34.2929 30.2929C34.1054 30.4804 34 30.7348 34 31V34H30V31C30 30.7348 29.8946 30.4804 29.7071 30.2929C29.5196 30.1054 29.2652 30 29 30H26V26H29C29.2652 26 29.5196 25.8946 29.7071 25.7071C29.8946 25.5196 30 25.2652 30 25V22H34V25C34 25.2652 34.1054 25.5196 34.2929 25.7071C34.4804 25.8946 34.7348 26 35 26H38V30Z"
        fill={color || colors.green.dark}
      />
    </Svg>
  );
}
