import Svg, { Path } from "svgs";

import colors from "../colors.json";

import { IconProps, DISABLED_COLOR } from ".";

const CheckmarkInCircleFilled: React.FC<IconProps> = (props) => {
  const color = (props.disabled ? DISABLED_COLOR : props.color) ?? colors.green.DEFAULT;
  return (
    <Svg width={props.size} height={props.size} fill="none" viewBox="0 0 20 20">
      <Path
        fill={color}
        d="M10 20C4.48 20 0 15.52 0 10v-.2A10 10 0 1 1 10 20ZM5.41 9.59 4 11l4 4 8-8-1.41-1.42L8 12.17 5.41 9.59Z"
      />
    </Svg>
  );
};

export default CheckmarkInCircleFilled;
