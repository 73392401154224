import * as React from "react";
import Svg, { Path } from "svgs";

import colors from "../colors.json";

import { IconProps, DISABLED_COLOR } from ".";

export default function SignOutIcon(props: IconProps) {
  const color = props.disabled ? DISABLED_COLOR : props.color;
  return (
    <Svg height={props.size} width={props.size} fill="none">
      <Path
        d="M11.2159 12.0151H21.9852"
        stroke={color || colors.gray[800]}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M18.9083 8.93823L21.9852 12.0152L18.9083 15.0921"
        stroke={color || colors.gray[800]}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M19.216 18.9231C17.8345 20.363 16.0539 21.3567 14.103 21.7764C12.1522 22.196 10.1204 22.0224 8.26906 21.2779C6.41769 20.5334 4.83138 19.2521 3.71419 17.5987C2.59699 15.9453 2 13.9955 2 12C2 10.0045 2.59699 8.05471 3.71419 6.40131C4.83138 4.74791 6.41769 3.46656 8.26906 2.72207C10.1204 1.97757 12.1522 1.80399 14.103 2.22364C16.0539 2.6433 17.8345 3.637 19.216 5.07693"
        stroke={color || colors.gray[800]}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
