import { useEffect } from "react";
import { Platform } from "react-native";

export function useKeyDown(
  ref: React.MutableRefObject<unknown>,
  handler: (event: KeyboardEvent) => void,
  deps?: unknown[]
): void {
  useEffect(() => {
    if (Platform.OS !== "web") {
      console.warn("Keyboard interaction is not supported on non-web platform");
      return;
    }

    if (ref.current === null) return;

    // We've made sure that we're on web, so this must point to an HTML element.
    const domElement = ref.current as HTMLElement;

    domElement.addEventListener("keydown", handler);

    return () => {
      domElement.removeEventListener("keydown", handler);
    };
    // Just run effect once.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps ?? []);

  return;
}

export default useKeyDown;
