import _debounce from "lodash/debounce";
import React, { useCallback, useMemo } from "react";
import {
  NativeSyntheticEvent,
  TextInputSubmitEditingEventData,
  TextInput as RNTextInput,
} from "react-native";

import TextInput, { TextInputProps } from "src/foundations/ui/TextInput";
import useControlled from "src/ui/hooks/useControlled";

type LimitedTextInputProps = Omit<TextInputProps, "onChangeText" | "value">;

export type SearchInputProps = LimitedTextInputProps & {
  /**
   * If this is given, it makes the component a controlled component.
   */
  value?: string;
  /**
   * Initial value for when you want to make this an uncontrolled component.
   */
  initialValue?: string;
  onInputChange: (text: string) => void;
  placeholder?: string;
  accessibilityLabel?: string;
  disabled?: boolean;
  debounceThreshold?: number;
  onSubmitEditing?: (e: NativeSyntheticEvent<TextInputSubmitEditingEventData>) => void;
};

export const SearchInput = React.forwardRef<RNTextInput, SearchInputProps>(function SearchInput(
  props,
  ref
) {
  const {
    onInputChange,
    onSubmitEditing,
    placeholder = "Search",
    accessibilityLabel = "Search",
    disabled = false,
    debounceThreshold = 200,
    initialValue = "",
    value,
    ...rest
  } = props;
  const [searchTerm, setSearchTerm] = useControlled({
    componentName: "SearchInput",
    stateName: "searchTerm",
    default: initialValue,
    controlled: value,
  });

  const onDebouncedInputChange = useMemo(
    () => _debounce(onInputChange, debounceThreshold),
    [debounceThreshold, onInputChange]
  );

  const handleSearchTermUpdate = useCallback(
    (searchTextInput: string) => {
      // If search input is an uncontrolled component, we'll let the consumer deals with any
      // debouncing need.
      const isUncontrolled = value === undefined;
      if (isUncontrolled) {
        onDebouncedInputChange(searchTextInput);
      } else {
        onInputChange(searchTextInput);
      }

      setSearchTerm(searchTextInput);
    },
    [onDebouncedInputChange, onInputChange, setSearchTerm, value]
  );

  return (
    <TextInput
      ref={ref}
      value={searchTerm}
      onChangeText={handleSearchTermUpdate}
      placeholder={placeholder}
      accessibilityLabel={accessibilityLabel}
      accessibilityRole="searchbox"
      icon="search"
      disabled={disabled}
      onSubmitEditing={onSubmitEditing}
      {...rest}
    />
  );
});

export default SearchInput;
