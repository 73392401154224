import { useRouter } from "next/router";
import { useEffect } from "react";

import { useAuthContext } from "src/auth/hooks/useAuthContext";

type UserBoundaryProps = {
  redirect?: boolean;
};

export const UserBoundary: React.FC<UserBoundaryProps> = ({ children, redirect = true }) => {
  const { status } = useAuthContext();
  const router = useRouter();

  useEffect(() => {
    if (status !== "unknown" && status !== "authenticated" && redirect === true) {
      router.push(`/sign-in?rdp=${router.asPath}`);
    }
  }, [status, router, redirect]);

  return status === "authenticated" ? <>{children}</> : null;
};
