import {Platform, TextProps} from "react-native";

type TextA11yRoles = TextProps["accessibilityRole"];
const missingOnNative = ["label", "listitem"];
export function filterA11yRoleByPlatform(role: string): TextA11yRoles {
  switch (Platform.OS) {
    case "android":
    case "ios":
      if (missingOnNative.indexOf(role) > -1) return undefined;
      return role as TextA11yRoles;
    default:
      return role as TextA11yRoles;
  }
}
