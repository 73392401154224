export const AUTH_TOKEN_STORAGE_KEY = "apiTokenV2";
export const CURRENT_USER_ID_STORAGE_KEY = "currentUserId";
export const TRUSTED_DEVICE_CODES_STORAGE_KEY = "trustedDeviceCodes";
export const CarbonHeaders = {
  AUTHORIZATION: "authorization",
  APP_TYPE: "carbonapptype",
  PLATFORM_TYPE: "carbonplatformtype",
  APP_VERSION: "carbonappversion",
  DEVICE_ID: "carbondeviceid",
  VISIT_ID: "carbonvisitid",
  VISITOR_ID: "carbonvisitorid",
} as const;
export const CustomHttpStatus = {
  NEED_TWO_FACTOR_AUTH: 250,
} as const;
