import noop from "lodash/noop";
import { Pressable } from "react-native";

import { useHover } from "src/foundations/hooks/useHover";
import { tailwind } from "src/foundations/styles";

export type MenuItemProps = {
  disabled?: boolean;
  onPress?: () => void;
};

export const MenuItem: React.FC<MenuItemProps> = (props) => {
  const { onPress = noop } = props;
  const [ref, hovered] = useHover();

  return (
    <Pressable
      ref={ref}
      accessibilityRole="menuitem"
      disabled={props.disabled}
      // TODO: Only the first item should be focusable per wai-aria-practices, but since keyboard
      // interaction is non-trivial, we just let it use focus (tabs) instead of up and down arrow
      // keys. Do the keyboard integration in `MenuList`.
      // https://www.w3.org/TR/wai-aria-practices/#keyboard-interaction-12
      focusable={true}
      onPress={onPress}
      style={[tailwind("p-2 rounded"), hovered && tailwind("bg-gray-100")]}
    >
      {props.children}
    </Pressable>
  );
};

export default MenuItem;
