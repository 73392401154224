import { useContext } from "react";

import { AuthContext, AuthContextData } from "src/auth/contexts/AuthProvider";

export function useAuthContext(): AuthContextData {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw Error("useAuth must be used within an AuthProvider");
  }
  return context;
}
