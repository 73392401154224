import { UUID } from "src/common/types/primitives";
import { monolithFetcher } from "src/common/utils/fetching";

export type GetMeAsDoctorResponse = {
  userId: UUID;
};

/* istanbul ignore next */
// TODO: This is a GET so see if we could use SWR instead
export async function getMeAsDoctor(): Promise<Response> {
  return monolithFetcher("/doc/doctors/me");
}
