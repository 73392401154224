import { useRouter } from "next/router";
import { useEffect, createContext, useCallback, useReducer } from "react";

import { getMeAsDoctor, GetMeAsDoctorResponse } from "src/auth/api/getMeAsDoctor";
import { login, LoginPayload, LoginResponse } from "src/auth/api/login";
import { logout } from "src/auth/api/logout";
import { CustomHttpStatus } from "src/auth/constants";
import { getTrustedDeviceCode, setTrustedDeviceCode } from "src/auth/utils/trustedDeviceCode";
import { clearCurrentUserToken, getTokenForCurrentUser, setTokens } from "src/auth/utils/userToken";
import { UUID } from "src/common/types/primitives";
import { asyncNoop } from "src/common/utils";

type AuthStatus = "unknown" | "authenticated" | "unauthenticated" | "needTwoFactorAuth" | "error";
type SignInCredentials = {
  email: string;
  password: string;
  twoFactorCode?: string;
  rememberDevice?: boolean;
  trustedDeviceCode?: string;
};
type AuthProviderProps = {
  onSignOut?: () => Promise<unknown>;
};
type AuthProviderState = Pick<AuthContextData, "userId" | "status" | "error" | "lastFour">;
const defaultState: AuthProviderState = {
  userId: undefined,
  status: "unknown",
  error: undefined,
  lastFour: undefined,
};

export type AuthContextData = {
  status: AuthStatus;
  userId?: UUID;
  lastFour?: string;
  error?: Error;
  /**
   * Submits credentials to identity provider (API monolith for now) and handles different
   * responses. Also stores auth token for future usage.
   *
   * Handles errors internally and doesn't throw them to consumer.
   */
  signIn: (credentials: SignInCredentials) => Promise<void>;
  /**
   * Redirect user back to the login page and purge user data from browser and memory. `onSignOut`
   * handler will also be called.
   */
  signOut: () => Promise<void>;
  /**
   * Finalize sign in process by redirecting user into the app. This is separate call from
   * `signIn()` because signing-in could result in an half-way success state: "needTwoFactorAuth".
   * If called before user is fully authenticated, it will be a noop.
   */
  letUserIn: () => void;
  /**
   * Call when user bail out of two-factor auth flow.
   */
  cancelTwoFactorAuth: () => void;
};

function reducer(state: AuthProviderState, action: any): AuthProviderState {
  switch (action.type) {
    case "GET_STORED_TOKEN_FAILED": {
      return { ...state, status: "unauthenticated", userId: undefined };
    }
    case "GET_USER_ID_FAILED": {
      return { ...state, status: "unauthenticated", userId: undefined };
    }
    case "GET_USER_ID_DONE": {
      return { ...state, status: "authenticated", userId: action.payload.userId };
    }
    case "LOGIN_STARTED": {
      return { ...state, userId: undefined, error: undefined };
    }
    case "LOGIN_FAILED": {
      if (state.status === "needTwoFactorAuth") {
        return { ...state, error: action.payload.error };
      }

      return { ...state, status: "unauthenticated", error: action.payload.error };
    }
    case "2FA_NEEDED": {
      return { ...state, status: "needTwoFactorAuth", lastFour: action.payload.lastFour };
    }
    case "2FA_CANCELLED": {
      return { ...state, status: "unauthenticated", lastFour: undefined };
    }
    case "LOGIN_DONE": {
      return { ...state, status: "authenticated", userId: action.payload.userId };
    }
    case "ERROR": {
      return { ...state, status: "error", error: action.payload.error };
    }
    case "LOGOUT_DONE": {
      return { ...state, status: "unauthenticated", userId: undefined, error: undefined };
    }
    default:
      return state;
  }
}

export const AuthContext = createContext<AuthContextData | undefined>(undefined);

export const AuthProvider: React.FC<AuthProviderProps> = ({ children, onSignOut = asyncNoop }) => {
  const router = useRouter();
  const [state, dispatch] = useReducer(reducer, defaultState);

  useEffect(() => {
    let mounted = true;

    (async () => {
      try {
        const token = getTokenForCurrentUser();
        if (!token) {
          mounted && dispatch({ type: "GET_STORED_TOKEN_FAILED" });
          return;
        }

        const doctorResponse = await getMeAsDoctor();
        if (doctorResponse.status >= 400) {
          mounted && dispatch({ type: "GET_USER_ID_FAILED" });
          return;
        }

        const doctorResponseJson: GetMeAsDoctorResponse = await doctorResponse.json();
        const userId: UUID = doctorResponseJson.userId;
        if (!userId) {
          mounted && dispatch({ type: "GET_USER_ID_FAILED" });
          return;
        }

        mounted && dispatch({ type: "GET_USER_ID_DONE", payload: { userId } });
      } catch (error) {
        mounted && dispatch({ type: "ERROR", payload: { error } });
      }
    })();

    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    if (state.status === "unauthenticated") {
      clearCurrentUserToken();
    }
  }, [state.status]);

  const signIn = useCallback(async function signIn(loginPayload: LoginPayload) {
    try {
      dispatch({ type: "LOGIN_STARTED" });

      const trustedDeviceCode = getTrustedDeviceCode(loginPayload.email);
      const response = await login({ ...loginPayload, trustedDeviceCode });

      if (response.status >= 400) {
        dispatch({ type: "LOGIN_FAILED", payload: { error: new Error(response.statusText) } });
        return;
      }

      if (response.status === CustomHttpStatus.NEED_TWO_FACTOR_AUTH) {
        const lastFour = await response.text();
        dispatch({ type: "2FA_NEEDED", payload: { lastFour } });
        return;
      }

      const bearerToken = response.headers.get("x-auth-token");
      const loginResponse: LoginResponse = await response.json();

      if (!bearerToken || !loginResponse.userId) {
        dispatch({
          type: "ERROR",
          payload: { error: new Error("Auth token or user id is missing.") },
        });
        return;
      }

      setTokens(loginResponse.userId, bearerToken);
      if (loginResponse.trustedDeviceCode) {
        setTrustedDeviceCode(loginPayload.email, loginResponse.trustedDeviceCode);
      }

      const doctorResponse = await getMeAsDoctor();
      if (doctorResponse.status >= 400) {
        dispatch({ type: "LOGIN_FAILED", payload: { error: new Error("Invalid Credentials") } });
        return;
      }

      dispatch({ type: "LOGIN_DONE", payload: { userId: loginResponse.userId } });
    } catch (error) {
      dispatch({ type: "ERROR", payload: { error } });
    }
  }, []);

  const signOut = useCallback(
    async function signOut() {
      try {
        await Promise.all([onSignOut(), router.push("/sign-in"), logout()]);
        dispatch({ type: "LOGOUT_DONE" });
      } catch (error) {
        dispatch({ type: "ERROR", payload: { error } });
      }
    },
    [router, onSignOut]
  );

  const letUserIn = useCallback(async () => {
    if (state.status !== "authenticated") return;
    const redirectPath = router.query.rdp || "/";
    await router.replace(redirectPath as string);
  }, [router, state.status]);

  const cancelTwoFactorAuth = useCallback(async function cancelTwoFactorAuth() {
    dispatch({ type: "2FA_CANCELLED" });
  }, []);

  return (
    <AuthContext.Provider value={{ ...state, letUserIn, signIn, signOut, cancelTwoFactorAuth }}>
      {children}
    </AuthContext.Provider>
  );
};
