import { View } from "react-native";

import { UserBoundary } from "src/auth/components/UserBoundary";
import { tailwind } from "src/foundations/styles";
import TopNav from "src/ui/components/TopNav";

export const DefaultLayout: React.FC = (props) => {
  return (
    <UserBoundary>
      <View
        style={[tailwind("flex flex-col flex-nowrap"), { height: "100vh", maxHeight: "100vh" }]}
      >
        <TopNav />

        <main style={tailwind("w-full h-full flex flex-row flex-auto overflow-hidden")}>
          {props.children}
        </main>
      </View>
    </UserBoundary>
  );
};
