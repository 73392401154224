import * as React from "react";
import Svg, { Path, Circle } from "svgs";

import colors from "../colors.json";

import { IconProps, DISABLED_COLOR } from ".";

export default function CircledExclamationPointIcon(props: IconProps) {
  const color = (props.disabled ? DISABLED_COLOR : props.color) || colors.red.dark;
  return (
    <Svg width={props.size} height={props.size} viewBox="0 0 20 20" fill="none">
      <Circle cx="10" cy="10" r="8" fill={color} />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 4.25a.75.75 0 0 1 .75.75v5a.75.75 0 0 1-1.5 0V5a.75.75 0 0 1 .75-.75Z"
        fill={colors.white.DEFAULT}
      />
      <Path d="M11.5 13.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z" fill={colors.white.DEFAULT} />
    </Svg>
  );
}
