import useEventListener from "@use-it/event-listener";
import React, { Ref, useCallback } from "react";
import { View, StyleProp, ViewStyle } from "react-native";

import { Paragraph, Icon } from "..";
import { useHover } from "../../hooks/useHover";
import { tailwind } from "../../styles";
import { Maybe } from "../../types";
import { IconName } from "../icons";

export type Item = {
  label: string;
  value: Maybe<string>;
};

export type BaseSelectProps =
  | (NormalSelectProps & { selectType: "normal" })
  | (HeadingSelectProps & { selectType: "heading" });

// public type for Normal Select component
export type NormalSelectProps = HeadingSelectProps & {
  icon?: IconName;
  items: Array<Item>; // require items, not optional here
};

// Public types for Heading Select Component
export type HeadingSelectProps = {
  // if passed, there will be a list option for "deselecting" or resetting the
  // selected `value` to `null`, which would put the list back at its initial
  // unselected state
  deselectValueLabel?: string;
  isOpen?: boolean;
  items?: Array<Item>;
  onClose: () => void;
  onOpen: () => void;
  // TODO: Added second parameter to allow callers access to the item that produces the value
  onValueChange: (val: Maybe<string>, item?: Maybe<Item>) => void;
  placeholder: string;
  style?: StyleProp<ViewStyle>; // todo
  // must match with child item "value" prop
  value?: Maybe<string>;
  testID?: string;
  searchable?: boolean;
  // TODO: Determines whether component should do local search or skip it
  useLocalSearch?: boolean;
  // TODO: Handler for when filter text changes
  onSearchTextChange?: (text: string) => void;
  // available in case users need to specify something other than value for options key
  keyExtractor?: (item: Item, indexNumber: number) => string;
  // TODO: Support these widths.
  // TODO: These needs to go into some sort of a "base" prop
  /**
   * If "auto" (default), width adjusts to the selected value. If "full", the component fills the
   * parent container.
   */
  width?: "full" | "auto";
  // TODO: Support accessibilityLabelledBy for a11y consideration
  accessibilityLabelledBy?: string;
};

/**
 * check if the passed in `value` is valid, and log an error if isn't.
 * TODO: This would also be good to hook up to bug reporting tools
 *
 * in the case on an invalid `value`, we just want to show the list in its
 * default state.
 */
export function getSelected(items: Array<Item> | undefined, value?: Maybe<string>) {
  const selected = !!value && items ? items.find((item) => item.value === value) : null;
  if (!!value && !selected) console.error("Invalid value passed to select");
  return selected;
}

type OptionProps = {
  label: string;
  value: Maybe<string>;
  selected?: boolean;
};

export function Option(props: OptionProps) {
  const [ref, hovered] = useHover();
  return (
    // TODO: Outer padding is unnecessary when rendered inside a Popover
    // <View ref={ref as Ref<View>} style={tailwind(`w-full px-2 `)}>
    <View ref={ref as Ref<View>} style={tailwind(`w-full`)}>
      <View
        style={tailwind(
          `flex-row justify-between items-center w-full px-3 py-4 rounded-sm ${
            hovered ? "bg-gray-50" : ""
          }`
        )}
      >
        {/*
          TODO: Support non-green text. BaseSelect should have the the least amount of styling
          possible. Any coloring should be done by a wrapper component that merges app-specific
          palette with the BaseSelect.
          TODO: Update the fontsize of item labels to be 14px corresponding to level 2 in Paragraph component
        */}
        {/* <Paragraph level={1} accessibilityRole="listitem" style={tailwind("text-green-dark")}> */}
        <Paragraph level={2} accessibilityRole="listitem">
          {props.label}
        </Paragraph>
        {props.selected && <Icon size={12} name="check" />}
      </View>
    </View>
  );
}

export function useDropdownCloseHandlers(selectRef: React.RefObject<View>, onClose: () => void) {
  /**
   * Set up handlers for the "click out" and "escape key pressed" events to
   * close the dropdown if it's open.
   *
   * 1. create a ref to pass to the Select component's wrapper
   * 2. setup listeners with useCallback (1 for escape, one for click)
   * 3. useEventListener to connect those listeners to the actual events, and
   *    also clean up the listeners when unmounted
   */
  const escapeListener = useCallback(
    (e: KeyboardEvent) => {
      if (selectRef.current && e.key === "Escape") onClose();
    },
    [onClose, selectRef]
  );
  const clickListener = useCallback(
    (e: MouseEvent) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (selectRef.current && !(selectRef.current as any).contains(e.target)) onClose();
    },
    [onClose, selectRef]
  );
  useEventListener("click", clickListener, document, { capture: true });
  useEventListener("keyup", escapeListener, document);
}
