import { useState, useRef, useCallback } from "react";
import { View, Pressable, Text } from "react-native";

import { useAuthContext } from "src/auth/hooks/useAuthContext";
import { useUserContext } from "src/common/contexts";
import { tailwind } from "src/foundations/styles";
import Caption from "src/foundations/ui/Caption";
import Avatar from "src/ui/components/Avatar";
import { Menu, MenuItem } from "src/ui/components/Menu";
import { NextLink } from "src/ui/components/NextLink/NextLink";

export const NavigationMenu: React.FC = () => {
  const { user } = useUserContext();
  const { signOut } = useAuthContext();
  const [isOpen, setIsOpen] = useState(false);
  const anchorRef = useRef<View | null>(null);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <Pressable onPress={() => setIsOpen(!isOpen)} accessibilityRole="button">
      <View
        ref={anchorRef}
        style={tailwind("flex-row p-1 rounded-full bg-gray-800  bg-opacity-10")}
      >
        <View>
          <Avatar name={user ? user.firstName + user.lastName : ""} />
          <Menu
            nativeID="basicMenu"
            anchor={anchorRef}
            placement="bottom right"
            isOpen={isOpen}
            onClose={handleClose}
            accessibilityLabelledBy="menuTrigger"
          >
            <MenuItem onPress={() => signOut()}>
              <Text>Logout</Text>
            </MenuItem>
            <MenuItem onPress={() => setIsOpen(false)}>
              <NextLink
                href="/settings/rules"
                anchorProps={{ accessibilityLabel: "Carbon Account Settings" }}
              >
                <Text>Carbon Account Settings</Text>
              </NextLink>
            </MenuItem>
          </Menu>
        </View>
        <View style={tailwind("flex-row pl-2 pr-3 items-center")} nativeID="menuTrigger">
          <Caption level={2} weight={"bold"}>
            My Account
          </Caption>
        </View>
      </View>
    </Pressable>
  );
};

export default NavigationMenu;
