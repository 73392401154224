import React, { useState, useEffect, useRef } from "react";
import { Animated, View } from "react-native";
import Svg, { Path } from "svgs";

import { tailwind } from "../styles";

import colors from "./colors.json";

/**
 * The loading spinner uses ReactNative's Animated API to fade in/out the
 * small dots in the carbon logo one at a time indefinitely
 */
export default function LoadingSpinner({ size = 48 }: { size?: number }) {
  // the hidden dot cycles every 300ms, the dots should animate in and out.
  const [hiddenDot, setHiddenDot] = useState(0);

  // this value is the visible opacity of the active dot (the one fading out and back in)
  const opacity = useRef(new Animated.Value(1)).current;

  // Animate the dot out, then back in. Once a single cycle completes, it'll
  // call setHiddenDot to advance to the next dot
  useEffect(() => {
    Animated.sequence([
      Animated.timing(opacity, {
        toValue: 0,
        duration: 100,
        useNativeDriver: false,
      }),
      Animated.timing(opacity, {
        toValue: 1,
        duration: 100,
        delay: 75,
        useNativeDriver: false,
      }),
    ]).start(() => setHiddenDot((hiddenDot + 1) % 6));
  }, [hiddenDot, opacity]);

  /**
   * Since the SVG components themselves can't take Animated.Value props, and
   * Can't be used with createAnimatedComponent (since they're functional components)
   * we have to render separate views with an svg in each of them, and animate the
   * opacity of the wrapping view itself
   *
   * That's why you'll see 6 views below, each containing an SVG with the
   * contents of a single dot in the carbon logo each
   */
  return (
    <View style={{ width: size, height: size }}>
      <Animated.View
        style={[tailwind("absolute h-full w-full"), { opacity: hiddenDot === 0 ? opacity : 1 }]}
      >
        <Svg height={size} width={size} viewBox="0 0 19 20" fill="none">
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.18938 4.45089C10.4144 4.45089 11.4076 3.45715 11.4076 2.23131C11.4076 1.00546 10.4144 0.0117188 9.18938 0.0117188C7.96431 0.0117188 6.97119 1.00546 6.97119 2.23131C6.97119 3.45715 7.96431 4.45089 9.18938 4.45089Z"
            fill={colors.gray[200]}
          />
        </Svg>
      </Animated.View>
      <Animated.View
        style={[tailwind("absolute h-full w-full"), { opacity: hiddenDot === 1 ? opacity : 1 }]}
      >
        <Svg height={size} width={size} viewBox="0 0 19 20" fill="none">
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.1616 7.93842C17.0366 7.93842 17.746 7.2286 17.746 6.353C17.746 5.47739 17.0366 4.76758 16.1616 4.76758C15.2865 4.76758 14.5771 5.47739 14.5771 6.353C14.5771 7.2286 15.2865 7.93842 16.1616 7.93842Z"
            fill={colors.gray[200]}
            opacity={100}
          />
        </Svg>
      </Animated.View>
      <Animated.View
        style={[tailwind("absolute h-full w-full"), { opacity: hiddenDot === 2 ? opacity : 1 }]}
      >
        <Svg height={size} width={size} viewBox="0 0 19 20" fill="none">
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.1607 15.8659C17.3858 15.8659 18.3789 14.8722 18.3789 13.6463C18.3789 12.4205 17.3858 11.4268 16.1607 11.4268C14.9356 11.4268 13.9425 12.4205 13.9425 13.6463C13.9425 14.8722 14.9356 15.8659 16.1607 15.8659Z"
            fill={colors.gray[200]}
          />
        </Svg>
      </Animated.View>
      <Animated.View
        style={[tailwind("absolute h-full w-full"), { opacity: hiddenDot === 3 ? opacity : 1 }]}
      >
        <Svg height={size} width={size} viewBox="0 0 19 20" fill="none">
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.18938 19.988C10.4144 19.988 11.4076 18.9943 11.4076 17.7684C11.4076 16.5426 10.4144 15.5488 9.18938 15.5488C7.96431 15.5488 6.97119 16.5426 6.97119 17.7684C6.97119 18.9943 7.96431 19.988 9.18938 19.988Z"
            fill={colors.gray[200]}
          />
        </Svg>
      </Animated.View>
      <Animated.View
        style={[tailwind("absolute h-full w-full"), { opacity: hiddenDot === 4 ? opacity : 1 }]}
      >
        <Svg height={size} width={size} viewBox="0 0 19 20" fill="none">
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.2176 15.2314C3.09265 15.2314 3.80202 14.5216 3.80202 13.646C3.80202 12.7704 3.09265 12.0605 2.2176 12.0605C1.34255 12.0605 0.633179 12.7704 0.633179 13.646C0.633179 14.5216 1.34255 15.2314 2.2176 15.2314Z"
            fill={colors.gray[200]}
          />
        </Svg>
      </Animated.View>
      <Animated.View
        style={[tailwind("absolute h-full w-full"), { opacity: hiddenDot === 5 ? opacity : 1 }]}
      >
        <Svg height={size} width={size} viewBox="0 0 19 20" fill="none">
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.21819 8.57296C3.44326 8.57296 4.43637 7.57922 4.43637 6.35338C4.43637 5.12753 3.44326 4.13379 2.21819 4.13379C0.993116 4.13379 0 5.12753 0 6.35338C0 7.57922 0.993116 8.57296 2.21819 8.57296Z"
            fill={colors.gray[200]}
          />
        </Svg>
      </Animated.View>
    </View>
  );
}
