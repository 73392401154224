import React from "react";
import { StyleProp, Text } from "react-native";
import { StyleSheet, TextStyle } from "react-native";

import Colors from "./colors.json";

// TODO: Exported this so we can access it in other places, e.g. Chip
// TODO: see about cascading styles between paragraphs
export type ParagraphProps = {
  level?: 1 | 2;
  weight?: "light" | "bold" | "normal";
  numberOfLines?: number;
  // TODO: added ability to specify another color
  color?: string;
  style?: StyleProp<TextStyle>;
  children: React.ReactNode;
  accessibilityRole?: "header" | "button" | "label" | "link" | "listitem" | "status";
  // TODO: Add this to foundation.
  /**
   * Only use as a last resort. https://testing-library.com/docs/queries/about/#priority
   */
  testID?: string;
  // TODO: Add this to foundation.
  nativeID?: string;
};

export default function Paragraph({
  level = 1,
  weight = "normal",
  numberOfLines,
  style,
  children,
  // TODO: added
  color = undefined,
  accessibilityRole,
  // TODO: Add this to foundation.
  nativeID,
  // TODO: Add this to foundation.
  testID,
}: ParagraphProps) {
  return (
    <Text
      // TODO: Add this to foundation.
      nativeID={nativeID}
      // TODO: Add this to foundation.
      testID={testID}
      accessibilityRole={accessibilityRole}
      numberOfLines={numberOfLines}
      style={[
        paragraphStyles[level],
        paragraphStyles[weight],
        style,
        // TODO: added to override color if requested
        color ? { color } : undefined,
      ]}
    >
      {children}
    </Text>
  );
}

const paragraphStyles = StyleSheet.create({
  bold: { fontFamily: "Inter-semibold" },
  light: { fontFamily: "Inter-light" },
  normal: { fontFamily: "Inter-regular" },
  1: {
    fontSize: 16,
    lineHeight: 24,
    color: Colors.gray[800],
  },
  2: {
    fontSize: 14,
    lineHeight: 20,
    color: Colors.gray[800],
  },
});
