import { memo } from "react";
import Svg, { Path } from "svgs";

import colors from "../colors.json";

import { IconProps, DISABLED_COLOR } from ".";

const GroupOfThree: React.FC<IconProps> = (props) => {
  const color = props.disabled ? DISABLED_COLOR : props.color;
  return (
    <Svg width={props.size} height={props.size} fill="none" viewBox="0 0 40 40">
      <Path
        // TOOD: RIght color?
        fill={color ?? "#1C4F75"}
        d="M0 20a20 20 0 1 1 40 0 20 20 0 0 1-40 0Z"
      />
      <Path
        fill="#fff"
        fillRule="evenodd"
        d="M17.58 10.25a3.42 3.42 0 1 1 3.25 5.73 5.42 5.42 0 0 1 4.59 5.35v2c0 .42-.34.75-.75.75h-1.32l-.6 6a.75.75 0 0 1-.75.67h-4c-.39 0-.7-.3-.75-.68l-.6-5.99h-1.32a.75.75 0 0 1-.75-.75v-2a5.42 5.42 0 0 1 4.59-5.35 3.42 3.42 0 0 1-1.59-5.73Zm2.42.5a1.92 1.92 0 1 0 0 3.83 1.92 1.92 0 0 0 0-3.83Zm-8.61 1.97a2.75 2.75 0 1 1 2.37 4.66.75.75 0 0 1-.43 1.37 2.58 2.58 0 0 0-2.58 2.58v1.25h.58c.38 0 .7.29.75.66l.58 4.68h2a.75.75 0 1 1 0 1.5H12a.75.75 0 0 1-.74-.66l-.59-4.68H10a.75.75 0 0 1-.75-.75v-2a4.08 4.08 0 0 1 3.35-4.01 2.75 2.75 0 0 1-1.21-4.6Zm1.94.7a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Zm11.4-.7a2.75 2.75 0 1 1 2.67 4.6 4.08 4.08 0 0 1 3.35 4.01v2c0 .42-.34.75-.75.75h-.67l-.59 4.68a.75.75 0 0 1-.74.66h-2.67a.75.75 0 0 1 0-1.5h2l.6-4.68a.75.75 0 0 1 .74-.66h.58v-1.25a2.58 2.58 0 0 0-2.58-2.58.75.75 0 0 1-.43-1.37 2.75 2.75 0 0 1-1.52-4.66Zm1.94.7a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Zm-6.67 4a3.92 3.92 0 0 0-3.92 3.91v1.25h1.25c.39 0 .71.3.75.68l.6 5.99h2.64l.6-6a.75.75 0 0 1 .75-.67h1.25v-1.25A3.92 3.92 0 0 0 20 17.42Z"
        clipRule="evenodd"
      />
    </Svg>
  );
};

export default memo(GroupOfThree) as typeof GroupOfThree;
