import * as React from "react";
import Svg, { Path } from "svgs";

import colors from "../colors.json";

import { IconProps, DISABLED_COLOR } from ".";

export default function OrganizationIcon(props: IconProps) {
  const color = props.disabled ? DISABLED_COLOR : props.color;
  return (
    <Svg width={props.size} height={props.size} viewBox="0 0 24 24" fill="none">
      <Path
        d="M12 11.5C14.7614 11.5 17 9.26142 17 6.5C17 3.73858 14.7614 1.5 12 1.5C9.23858 1.5 7 3.73858 7 6.5C7 9.26142 9.23858 11.5 12 11.5Z"
        stroke={color || colors.gray[800]}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M12 23.5C13.1046 23.5 14 22.6046 14 21.5C14 20.3954 13.1046 19.5 12 19.5C10.8954 19.5 10 20.3954 10 21.5C10 22.6046 10.8954 23.5 12 23.5Z"
        fill={color || colors.gray[800]}
      />
      <Path
        d="M21 23.5C22.1046 23.5 23 22.6046 23 21.5C23 20.3954 22.1046 19.5 21 19.5C19.8954 19.5 19 20.3954 19 21.5C19 22.6046 19.8954 23.5 21 23.5Z"
        fill={color || colors.gray[800]}
      />
      <Path
        d="M3 23.5C4.10457 23.5 5 22.6046 5 21.5C5 20.3954 4.10457 19.5 3 19.5C1.89543 19.5 1 20.3954 1 21.5C1 22.6046 1.89543 23.5 3 23.5Z"
        fill={color || colors.gray[800]}
      />
      <Path
        d="M3 17.5V14.5H12V11.5"
        stroke={color || colors.gray[800]}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M12 14.5H21V17.5"
        stroke={color || colors.gray[800]}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M12 14.5V17.5"
        stroke={color || colors.gray[800]}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M10.5002 5.79995C10.942 5.79995 11.3002 5.44178 11.3002 4.99995C11.3002 4.55812 10.942 4.19995 10.5002 4.19995C10.0584 4.19995 9.7002 4.55812 9.7002 4.99995C9.7002 5.44178 10.0584 5.79995 10.5002 5.79995Z"
        fill={color || colors.gray[800]}
      />
      <Path
        d="M13.5002 5.79995C13.942 5.79995 14.3002 5.44178 14.3002 4.99995C14.3002 4.55812 13.942 4.19995 13.5002 4.19995C13.0584 4.19995 12.7002 4.55812 12.7002 4.99995C12.7002 5.44178 13.0584 5.79995 13.5002 5.79995Z"
        fill={color || colors.gray[800]}
      />
      <Path
        d="M10 7.5C11 8.8 13 8.8 14 7.5"
        stroke={color || colors.gray[800]}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
