import { UUID } from "src/common/types/primitives";
import { monolithFetcher } from "src/common/utils/fetching";

export type LoginResponse = {
  userId: UUID;
  trustedDeviceCode?: string;
};

export type LoginPayload = {
  email: string;
  password: string;
  twoFactorCode?: string;
  rememberDevice?: boolean;
  trustedDeviceCode?: string;
};

/* istanbul ignore next */
export async function login(payload: LoginPayload): Promise<Response> {
  return monolithFetcher("/login", {
    method: "POST",
    body: JSON.stringify(payload),
  });
}
