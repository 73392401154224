import { ApolloClient, from, HttpLink, InMemoryCache } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { RestLink } from "apollo-link-rest";

import { graphQLFetcher, monolithFetcher } from "./fetching";

const httpLink = new HttpLink({
  fetch: graphQLFetcher,
});

const restLink = new RestLink({
  // Base URI is defined in monolithFetcher, endpoint path will be defined by the consumer.
  uri: "",
  customFetch: monolithFetcher,
});

const errorLink = onError(({ networkError, graphQLErrors }) => {
  if (networkError) {
    // TODO: Send to Sentry instead
    console.log(`[Network Error: ${networkError}`);
  }

  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) =>
      // TODO: Send to Sentry instead?
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    );
  }
});

const cache = new InMemoryCache();

export const client = new ApolloClient({
  link: from([errorLink, restLink, httpLink]),
  cache,
});
