import React from "react";

import { AnchorProps } from "./types";

// https://github.com/vercel/next.js/issues/7915#issuecomment-720149039
export default React.forwardRef<HTMLAnchorElement, React.PropsWithChildren<AnchorProps>>(
  function Anchor(props, ref) {
    const { accessibilityLabel, children, onPress, ...rest } = props;

    return (
      // <a /> already has default onKeyPress handler.
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events
      <a
        role="link"
        tabIndex={0}
        ref={ref}
        aria-label={accessibilityLabel}
        style={{ textDecoration: "none" }}
        onClick={onPress}
        {...rest}
      >
        {children}
      </a>
    );
  }
);
