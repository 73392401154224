import { useMemo } from "react";
import { View, Text } from "react-native";

import { tailwind } from "src/foundations/styles";

export type AvatarProps = {
  name?: string;
};

function getFirstTwoLetterOf(name: string) {
  const trimmedName = name.trim();
  return `${(trimmedName[0] ?? "").toUpperCase()}${trimmedName[1] ?? ""}`;
}

/**
 * https://stackoverflow.com/questions/3426404/create-a-hexadecimal-colour-based-on-a-string-with-javascript
 * @param str Any string
 * @returns 6-digit hex value. No pound sign.
 */
function turnStringToHexValue(str: string) {
  let hash = 0;
  let i;

  for (i = 0; i < str.length; i += 1) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }

  return color;
}

/**
 * https://24ways.org/2010/calculating-color-contrast
 * @param hexValue 6-digit hex value. No pound sign.
 * @returns "dark" | "light"
 */
function calculateBrightness(hexValue: string): "dark" | "light" {
  const r = parseInt(hexValue.substr(0, 2), 16);
  const g = parseInt(hexValue.substr(2, 2), 16);
  const b = parseInt(hexValue.substr(4, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? "light" : "dark";
}

export const Avatar: React.FC<AvatarProps> = ({ name = "" }) => {
  const displayLetters = useMemo(() => getFirstTwoLetterOf(name), [name]);
  const backgroundColorValue = useMemo(() => turnStringToHexValue(name), [name]);
  const color = useMemo(
    () => (calculateBrightness(backgroundColorValue) === "light" ? "#000" : "#FFF"),
    [backgroundColorValue]
  );

  return (
    <View
      // for ios
      accessibilityElementsHidden={true}
      // for android
      importantForAccessibility="no-hide-descendants"
      // for web
      aria-hidden={true}
      style={{
        ...tailwind("h-8 w-8 flex items-center justify-center rounded-full"),
        backgroundColor: `#${backgroundColorValue}`,
      }}
    >
      {/* Line height is set to 0 to create a more visually centered positioining */}
      <Text style={{ color, lineHeight: 0 }}>{displayLetters}</Text>
    </View>
  );
};

export default Avatar;
