import * as React from "react";
import Svg, { Path } from "svgs";

import colors from "../colors.json";

import { IconProps, DISABLED_COLOR } from ".";

export default function SearchIcon(props: IconProps) {
  const color = props.disabled ? DISABLED_COLOR : props.color;
  return (
    <Svg width={props.size} height={props.size} viewBox="0 0 16 16" fill="none">
      <Path
        d="M8.79199 11.5891C11.4792 10.4471 12.7318 7.3429 11.5897 4.65572C10.4477 1.96853 7.34353 0.715934 4.65635 1.85796C1.96916 2.99999 0.716567 6.10417 1.85859 8.79136C3.00062 11.4785 6.10481 12.7311 8.79199 11.5891Z"
        // TODO: make default color gray
        stroke={color || colors.gray[800]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M10.4623 10.4619L14.5625 14.5627"
        // TODO: make default color gray
        stroke={color || colors.gray[800]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
