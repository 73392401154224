import { memo } from "react";
import Svg, { Path } from "svgs";

import colors from "../colors.json";

import { IconProps, DISABLED_COLOR } from ".";

const DownTriangleSmall: React.FC<IconProps> = (props) => {
  const color = props.disabled ? DISABLED_COLOR : props.color;
  return (
    <Svg width={props.size} height={props.size} fill="none" viewBox="0 0 20 20">
      <Path
        fill={props.color ?? colors.gray[400]}
        d="M10.78 14.04a1 1 0 0 1-1.56 0L5.25 9.13a1 1 0 0 1 .78-1.63h7.94a1 1 0 0 1 .78 1.63l-3.97 4.9Z"
      />
    </Svg>
  );
};

export default memo(DownTriangleSmall) as typeof DownTriangleSmall;
