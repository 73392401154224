import Svg, { Path } from "svgs";

import colors from "../colors.json";

import { IconProps, DISABLED_COLOR } from ".";

export const ChevronUpIcon: React.FC<IconProps> = (props) => {
  const color = props.disabled ? DISABLED_COLOR : props.color;
  return (
    <Svg
      height={props.size}
      width={props.size}
      fill="none"
      viewBox="0 0 20 10"
      transform={"rotate(180)"}
    >
      <Path
        d="m2 1.571 8 6.857 8-6.857"
        stroke={color ?? colors.gray[400]}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
    </Svg>
  );
};

export default ChevronUpIcon;
