/* eslint-disable react-hooks/rules-of-hooks, react-hooks/exhaustive-deps */
import React from "react";

type UseControlledProps<T> = {
  controlled: T;
  default: T;
  componentName: string;
  stateName: string;
};

type UseControlledResult<T> = [T, (newValue: T) => void];

export function useControlled<T>({
  controlled,
  default: defaultProp,
  componentName,
  stateName = "value",
}: UseControlledProps<T>): UseControlledResult<T> {
  // isControlled is ignored in the hook dependency lists as it should never change.
  const { current: isControlled } = React.useRef(controlled !== undefined);
  const [valueState, setValue] = React.useState(defaultProp);
  const value = isControlled ? controlled : valueState;

  // Generally hooks need to run in the same order every single render, but
  // as this is being determined by the node env which will not change, the below
  // check will not violate that rule.
  if (process.env.NODE_ENV !== "production") {
    React.useEffect(() => {
      if (isControlled !== (controlled !== undefined)) {
        console.error(
          [
            `A component is changing the ${
              isControlled ? "" : "un"
            }controlled ${stateName} state of ${componentName} to be ${
              isControlled ? "un" : ""
            }controlled.`,
            "Elements should not switch from uncontrolled to controlled (or vice versa).",
            `Decide between using a controlled or uncontrolled ${componentName} ` +
              "element for the lifetime of the component.",
            "The nature of the state is determined during the first render. It's considered controlled if the value is not `undefined`.",
            "More info: https://fb.me/react-controlled-components",
          ].join("\n")
        );
      }
    }, [stateName, componentName, controlled]);

    const { current: defaultValue } = React.useRef(defaultProp);

    React.useEffect(() => {
      if (!isControlled && defaultValue !== defaultProp) {
        console.error(
          [
            `A component is changing the default ${stateName} state of an uncontrolled ${componentName} after being initialized. ` +
              `To suppress this warning opt to use a controlled ${componentName}.`,
          ].join("\n")
        );
      }
    }, [JSON.stringify(defaultProp)]);
  }

  const setValueIfUncontrolled = React.useCallback((newValue: T) => {
    if (!isControlled) {
      setValue(newValue);
    }
  }, []);

  return [value, setValueIfUncontrolled];
}

export default useControlled;
