import * as React from "react";
import Svg, { Path } from "svgs";

import colors from "../colors.json";

import { IconProps, DISABLED_COLOR } from ".";

export default function PeopleIcon(props: IconProps) {
  const color = props.disabled ? DISABLED_COLOR : props.color;
  return (
    <Svg width={props.size} height={props.size} viewBox="0 0 24 24" fill="none">
      <Path
        d="M21.869 20.8C21.9079 20.747 21.936 20.6867 21.9516 20.6228C21.9672 20.5588 21.97 20.4924 21.9599 20.4274C21.9497 20.3624 21.9269 20.3 21.8926 20.2438C21.8583 20.1876 21.8132 20.1388 21.76 20.1C14.596 14.878 7.33701 20.047 7.26501 20.1C7.16002 20.179 7.0903 20.296 7.07091 20.4259C7.05152 20.5558 7.08402 20.6881 7.16139 20.7943C7.23875 20.9004 7.35477 20.9718 7.48438 20.9931C7.61398 21.0144 7.74675 20.9838 7.85401 20.908C7.92101 20.86 14.589 16.108 21.171 20.908C21.2779 20.9861 21.4115 21.0186 21.5423 20.9984C21.6732 20.9781 21.7907 20.9068 21.869 20.8Z"
        fill={color || colors.green.DEFAULT}
      />
      <Path
        d="M14.513 5C11.681 5 9.37701 7.563 9.37701 10.715C9.37701 13.867 11.677 16.429 14.513 16.429C17.349 16.429 19.648 13.865 19.648 10.715C19.648 7.565 17.344 5 14.513 5ZM14.513 15.429C13.3417 15.3463 12.2504 14.8048 11.476 13.9222C10.7016 13.0396 10.3067 11.8871 10.377 10.715C10.3067 9.54282 10.7015 8.3902 11.4759 7.50741C12.2503 6.62463 13.3417 6.08295 14.513 6C15.6842 6.0832 16.7753 6.62499 17.5495 7.50774C18.3236 8.3905 18.7184 9.54298 18.648 10.715C18.7184 11.8869 18.3236 13.0393 17.5494 13.9219C16.7752 14.8045 15.6841 15.346 14.513 15.429Z"
        fill={color || colors.green.DEFAULT}
      />
      <Path
        d="M2.10001 18.179C2.13141 18.2368 2.17389 18.2878 2.22503 18.3291C2.27617 18.3704 2.33496 18.4012 2.39803 18.4198C2.4611 18.4384 2.52721 18.4444 2.59258 18.4373C2.65796 18.4303 2.72131 18.4105 2.77901 18.379C4.72525 17.2855 6.92583 16.7256 9.15801 16.756C9.29062 16.7601 9.41943 16.7114 9.5161 16.6205C9.61278 16.5296 9.6694 16.4041 9.67351 16.2715C9.67762 16.1389 9.62888 16.0101 9.53802 15.9134C9.44716 15.8167 9.32162 15.7601 9.18901 15.756C6.77907 15.7384 4.40486 16.3389 2.29301 17.5C2.17754 17.5646 2.0924 17.6723 2.05622 17.7996C2.02005 17.9269 2.03579 18.0633 2.10001 18.179Z"
        fill={color || colors.green.DEFAULT}
      />
      <Path
        d="M8.339 14.423H8.364C8.49661 14.4263 8.6251 14.3768 8.72121 14.2854C8.81732 14.194 8.87318 14.0681 8.8765 13.9355C8.87981 13.8029 8.83031 13.6744 8.73889 13.5783C8.64747 13.4822 8.52161 13.4263 8.389 13.423C7.25363 13.2907 6.21188 12.7291 5.47726 11.8534C4.74265 10.9776 4.37082 9.85408 4.438 8.713C4.36819 7.54115 4.76332 6.38904 5.53764 5.5067C6.31197 4.62435 7.40301 4.08296 8.574 4C9.33387 4.00276 10.075 4.23642 10.699 4.67C10.753 4.71047 10.8145 4.7396 10.8801 4.75564C10.9456 4.77168 11.0136 4.77431 11.0802 4.76335C11.1467 4.7524 11.2104 4.7281 11.2673 4.6919C11.3242 4.65571 11.3732 4.60837 11.4114 4.55275C11.4495 4.49712 11.476 4.43435 11.4892 4.36822C11.5025 4.30209 11.5022 4.23396 11.4885 4.16793C11.4747 4.10191 11.4477 4.03935 11.4091 3.98402C11.3706 3.9287 11.3212 3.88175 11.264 3.846C10.4739 3.29769 9.53572 3.00264 8.574 3C5.742 3 3.438 5.563 3.438 8.715C3.36944 10.1128 3.83939 11.4838 4.75109 12.5456C5.66279 13.6074 6.94687 14.2793 8.339 14.423Z"
        fill={color || colors.green.DEFAULT}
      />
    </Svg>
  );
}
