import Link, { LinkProps } from "next/link";

import Anchor, { AnchorProps } from "src/ui/components/Anchor";

// We always want to passHref for a11y purposes.
export type NextLinkProps = Omit<LinkProps, "passHref"> & {
  anchorProps?: AnchorProps;
};

/**
 * A convenient wrapper for Next's <Link /> component that enforces `passHref=true` for a11y and
 * provide a ref-forwarded anchor element (in web) as the direct children to receive the href.
 * https://nextjs.org/docs/api-reference/next/link#if-the-child-is-a-functional-component
 */
export const NextLink: React.FC<NextLinkProps> = (props) => {
  const { anchorProps, ...linkProps } = props;
  return (
    <Link {...linkProps} passHref>
      <Anchor {...anchorProps}>{props.children}</Anchor>
    </Link>
  );
};
