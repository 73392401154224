import React, { ReactNode } from "react";
import { View, Text, Pressable, GestureResponderEvent, StyleProp, ViewStyle } from "react-native";

import { tailwind } from "../styles";
import { Maybe } from "../types";

import { Icon, Paragraph } from ".";
import colors from "./colors.json";

/*  These two types could be imported directly from generated types, but
 *  leaving them redefined here just in case we move these components out
 *  to a separate library without access to the generated types
 */
export type PageCursor = {
  cursor: string;
  pageNumber: number;
};
export type PageInfo = {
  currentPageNumber: number;
  endCursor: string;
  firstPage?: Maybe<PageCursor>;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  lastPage?: Maybe<PageCursor>;
  nearbyPages: Array<PageCursor>;
  startCursor: string;
};

export enum PaginationDirection {
  FORWARD,
  BACKWARD,
}

export interface PaginationProps {
  onClick: (cursor: string, direction?: PaginationDirection) => void;
  pageCursors: PageInfo;
}

const Ellipses = () => (
  <CirclePressable disabled={true}>
    <Text style={tailwind("text-gray-800")}>&#8230;</Text>
  </CirclePressable>
);

type CirclePressableProps = {
  children: ReactNode;
  disabled: boolean;
  onPress?: (event: GestureResponderEvent) => void;
  style?: StyleProp<ViewStyle>;
  accessibilityLabel?: string;
};

function CirclePressable(props: CirclePressableProps) {
  return (
    <Pressable
      disabled={props.disabled}
      onPress={props.onPress}
      accessibilityRole="button"
      style={[tailwind("h-10 w-10 rounded-full justify-center items-center mx-1"), props.style]}
      accessibilityLabel={props.accessibilityLabel}
    >
      {props.children}
    </Pressable>
  );
}

type PageProps = {
  cursor: PageCursor;
  currentPageNumber: number;
  onClick: (cursor: string, page: number) => void;
};
const Page = (props: PageProps) => {
  const { cursor, pageNumber } = props.cursor;
  const isCurrent = pageNumber === props.currentPageNumber;
  return (
    <CirclePressable
      disabled={isCurrent}
      onPress={() => props.onClick(cursor, pageNumber)}
      style={isCurrent && tailwind("bg-gray-800")}
    >
      <Paragraph
        style={[
          tailwind("font-semibold"),
          isCurrent ? tailwind("text-white") : tailwind("text-gray-800"),
        ]}
      >
        {pageNumber}
      </Paragraph>
    </CirclePressable>
  );
};

export default function Pagination(props: PaginationProps) {
  const {
    pageCursors: {
      nearbyPages,
      firstPage,
      lastPage,
      startCursor,
      endCursor,
      hasNextPage,
      hasPreviousPage,
      currentPageNumber,
    },
    onClick,
  } = props;

  return (
    <View testID="pagination" style={tailwind("flex-row")}>
      {hasPreviousPage && (
        <CirclePressable
          disabled={!hasPreviousPage}
          onPress={() => onClick(startCursor, PaginationDirection.BACKWARD)}
          style={tailwind("bg-gray-100")}
          accessibilityLabel="go to previous page"
        >
          <View style={[{ transform: [{ rotate: "90deg" }] }]}>
            <Icon name="chevron-down" color={colors.gray[800]} size={16} />
          </View>
        </CirclePressable>
      )}
      {firstPage && (
        <>
          <Page
            cursor={firstPage}
            currentPageNumber={currentPageNumber}
            onClick={() => onClick(firstPage.cursor)}
          />
          <Ellipses />
        </>
      )}
      {nearbyPages.length > 1 &&
        nearbyPages.map((pageInfo) => (
          <Page
            cursor={pageInfo}
            key={pageInfo.cursor}
            currentPageNumber={currentPageNumber}
            onClick={() => onClick(pageInfo.cursor)}
          />
        ))}
      {lastPage && (
        <>
          <Ellipses />
          <Page
            cursor={lastPage}
            currentPageNumber={currentPageNumber}
            onClick={() => onClick(lastPage.cursor)}
          />
        </>
      )}

      {hasNextPage && (
        <CirclePressable
          disabled={!hasNextPage}
          onPress={() => onClick(endCursor)}
          style={tailwind("bg-gray-100")}
          accessibilityLabel="go to next page"
        >
          <View style={[{ transform: [{ rotate: "-90deg" }] }]}>
            <Icon name="chevron-down" color={colors.gray[800]} size={16} />
          </View>
        </CirclePressable>
      )}
    </View>
  );
}
