import * as React from "react";
import Svg, { Path } from "svgs";

import colors from "../colors.json";

import { IconProps, DISABLED_COLOR } from ".";

export default function Members(props: IconProps) {
  const color = props.disabled ? DISABLED_COLOR : props.color;
  return (
    <Svg width={props.size} height={props.size} viewBox="0 0 20 20" fill="none">
      <Path
        d="M8.30586 0.972412C7.81141 0.972412 7.32806 1.11903 6.91694 1.39374C6.50581 1.66844 6.18538 2.05889 5.99616 2.5157C5.80695 2.97252 5.75744 3.47519 5.8539 3.96014C5.95036 4.44509 6.18847 4.89055 6.5381 5.24018C6.88773 5.58981 7.33318 5.82791 7.81814 5.92438C8.30309 6.02084 8.80576 5.97133 9.26257 5.78211C9.71939 5.59289 10.1098 5.27246 10.3845 4.86134C10.6592 4.45022 10.8059 3.96687 10.8059 3.47241C10.8059 2.80937 10.5425 2.17349 10.0736 1.70465C9.60479 1.2358 8.9689 0.972412 8.30586 0.972412ZM8.30586 5.13908C7.97623 5.13908 7.65399 5.04133 7.37991 4.85819C7.10583 4.67506 6.89221 4.41476 6.76606 4.11022C6.63992 3.80567 6.60691 3.47056 6.67122 3.14726C6.73553 2.82396 6.89426 2.52699 7.12735 2.2939C7.36044 2.06081 7.65741 1.90208 7.98071 1.83777C8.30401 1.77346 8.63912 1.80647 8.94367 1.93261C9.24821 2.05876 9.50851 2.27238 9.69165 2.54646C9.87478 2.82054 9.97253 3.14278 9.97253 3.47241C9.97253 3.91444 9.79693 4.33836 9.48437 4.65092C9.17181 4.96348 8.74789 5.13908 8.30586 5.13908Z"
        fill={color || colors.gray[800]}
      />
      <Path
        d="M3.82251 8.01509L3.72251 12.2434C3.72038 12.3329 3.7471 12.4207 3.79873 12.4937C3.85036 12.5668 3.92415 12.6214 4.00917 12.6493L5.33584 13.0876L5.84084 18.6484C5.85079 18.7589 5.90422 18.861 5.9894 18.9321C6.07457 19.0032 6.1845 19.0375 6.29501 19.0276C6.40551 19.0176 6.50754 18.9642 6.57865 18.879C6.64976 18.7939 6.68412 18.6839 6.67417 18.5734L6.14501 12.7401C6.13768 12.6591 6.10678 12.5819 6.05614 12.5183C6.00549 12.4546 5.93731 12.4071 5.86001 12.3818L4.56667 11.9551L4.65001 8.29509C5.84743 7.73771 7.15609 7.46042 8.47667 7.48426H8.48584C8.54056 7.48481 8.59485 7.47457 8.64561 7.45414C8.69637 7.4337 8.74261 7.40347 8.78169 7.36517C8.82077 7.32686 8.85192 7.28124 8.87336 7.23089C8.89481 7.18055 8.90613 7.12648 8.90667 7.07176C8.90722 7.01704 8.89699 6.96275 8.87655 6.91199C8.85612 6.86123 8.82589 6.81499 8.78758 6.77591C8.74928 6.73683 8.70365 6.70568 8.65331 6.68424C8.60297 6.66279 8.54889 6.65147 8.49417 6.65093C6.95972 6.63919 5.44323 6.98138 4.06251 7.65093C3.99277 7.68353 3.93342 7.73482 3.89105 7.7991C3.84869 7.86338 3.82496 7.93815 3.82251 8.01509Z"
        fill={color || colors.gray[800]}
      />
      <Path
        d="M12.635 8.91667C13.047 8.91667 13.4498 8.79448 13.7924 8.56556C14.135 8.33664 14.4021 8.01127 14.5597 7.63059C14.7174 7.24991 14.7587 6.83102 14.6783 6.4269C14.5979 6.02277 14.3995 5.65155 14.1081 5.36019C13.8168 5.06884 13.4456 4.87042 13.0414 4.79003C12.6373 4.70965 12.2184 4.7509 11.8377 4.90859C11.457 5.06627 11.1317 5.33329 10.9028 5.6759C10.6738 6.0185 10.5517 6.42129 10.5517 6.83333C10.5517 7.10692 10.6055 7.37783 10.7102 7.63059C10.8149 7.88335 10.9684 8.11302 11.1618 8.30647C11.5525 8.69717 12.0825 8.91667 12.635 8.91667ZM12.635 5.58333C12.8822 5.58333 13.1239 5.65665 13.3294 5.794C13.535 5.93135 13.6952 6.12657 13.7898 6.35498C13.8844 6.58339 13.9092 6.83472 13.861 7.0772C13.8127 7.31967 13.6937 7.5424 13.5189 7.71722C13.3441 7.89203 13.1213 8.01108 12.8788 8.05932C12.6364 8.10755 12.385 8.08279 12.1566 7.98818C11.9282 7.89357 11.733 7.73336 11.5956 7.5278C11.4583 7.32224 11.385 7.08056 11.385 6.83333C11.385 6.50181 11.5167 6.18387 11.7511 5.94945C11.9855 5.71503 12.3035 5.58333 12.635 5.58333Z"
        fill={color || colors.gray[800]}
      />
      <Path
        d="M11.4625 18.5733L11.0533 14.0575C11.046 13.9765 11.0153 13.8995 10.9648 13.8358C10.9143 13.7722 10.8463 13.7246 10.7691 13.6991L9.83331 13.39L9.89664 10.6858C10.7552 10.2901 11.6892 10.0852 12.6346 10.0852C13.5799 10.0852 14.514 10.2901 15.3725 10.6858L15.4366 13.39L14.5008 13.6991C14.4238 13.7248 14.356 13.7724 14.3057 13.836C14.2553 13.8997 14.2247 13.9766 14.2175 14.0575L13.8066 18.5733C13.8017 18.6278 13.8076 18.6827 13.8239 18.735C13.8402 18.7872 13.8666 18.8357 13.9017 18.8777C13.9367 18.9198 13.9797 18.9545 14.0282 18.9799C14.0766 19.0053 14.1296 19.0209 14.1841 19.0258C14.2936 19.0332 14.4018 18.998 14.486 18.9277C14.5703 18.8574 14.6242 18.7573 14.6366 18.6483L15.0225 14.405L15.9916 14.0833C16.0764 14.0553 16.1499 14.0008 16.2013 13.9279C16.2528 13.855 16.2795 13.7675 16.2775 13.6783L16.1991 10.4041C16.1972 10.3268 16.1738 10.2516 16.1316 10.1869C16.0894 10.1221 16.0299 10.0704 15.96 10.0375C14.9264 9.52465 13.7883 9.25781 12.6346 9.25781C11.4808 9.25781 10.3427 9.52465 9.30914 10.0375C9.23918 10.0704 9.17976 10.1221 9.13753 10.1869C9.09529 10.2516 9.0719 10.3268 9.06998 10.4041L8.99248 13.6783C8.99047 13.7675 9.01716 13.855 9.06861 13.9279C9.12007 14.0008 9.19358 14.0553 9.27831 14.0833L10.2475 14.4041L10.6325 18.6475C10.6374 18.7022 10.6531 18.7554 10.6785 18.8041C10.704 18.8527 10.7389 18.8959 10.781 18.9311C10.8232 18.9663 10.8719 18.9929 10.9243 19.0093C10.9768 19.0257 11.0319 19.0316 11.0866 19.0266C11.1414 19.0217 11.1946 19.0061 11.2432 18.9806C11.2919 18.9551 11.3351 18.9203 11.3703 18.8781C11.4055 18.8359 11.4321 18.7872 11.4484 18.7348C11.4648 18.6823 11.4707 18.6272 11.4658 18.5725L11.4625 18.5733Z"
        fill={color || colors.gray[800]}
      />
    </Svg>
  );
}
