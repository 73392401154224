import BaseSelect from "./BaseSelect";
import { NormalSelectProps, HeadingSelectProps } from "./utils";

// TODO: Make this a different component in its own folder so we don't have to import both if we
// don't need it. Billing Hub doesn't need HeadingSelect, which I assume to be Enterprise-specific
// use case.
export const HeadingSelect = (props: HeadingSelectProps) => (
  <BaseSelect {...props} selectType="heading" />
);

export const Select = (props: NormalSelectProps) => <BaseSelect {...props} selectType="normal" />;
