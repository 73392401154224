import * as React from "react";
import Svg, { Path } from "svgs";

import colors from "../colors.json";

import { IconProps, DISABLED_COLOR } from ".";

export default function Group(props: IconProps) {
  const color = props.disabled ? DISABLED_COLOR : props.color;
  return (
    <Svg height={props.size} width={props.size} viewBox="0 0 24 24" fill="none">
      <Path
        d="M16.5 2.1001H5.5C5.10218 2.1001 4.72064 2.25813 4.43934 2.53944C4.15804 2.82074 4 3.20227 4 3.6001V20.6001C4 20.9979 4.15804 21.3795 4.43934 21.6608C4.72064 21.9421 5.10218 22.1001 5.5 22.1001H16.5C17.4283 22.1001 18.3185 21.7313 18.9749 21.075C19.6313 20.4186 20 19.5284 20 18.6001V5.6001C20 4.67184 19.6313 3.7816 18.9749 3.12522C18.3185 2.46885 17.4283 2.1001 16.5 2.1001ZM19 18.6001C19 19.2631 18.7366 19.899 18.2678 20.3679C17.7989 20.8367 17.163 21.1001 16.5 21.1001H5.5C5.36739 21.1001 5.24021 21.0474 5.14645 20.9537C5.05268 20.8599 5 20.7327 5 20.6001V3.6001C5 3.46749 5.05268 3.34031 5.14645 3.24654C5.24021 3.15278 5.36739 3.1001 5.5 3.1001H16.5C17.163 3.1001 17.7989 3.36349 18.2678 3.83233C18.7366 4.30117 19 4.93706 19 5.6001V18.6001Z"
        fill={color || colors.gray[800]}
      />
      <Path
        d="M12 7.1001C11.0111 7.1001 10.0444 7.39334 9.22215 7.94275C8.39991 8.49216 7.75904 9.27305 7.3806 10.1867C7.00217 11.1003 6.90315 12.1056 7.09608 13.0755C7.289 14.0455 7.76521 14.9364 8.46447 15.6356C9.16373 16.3349 10.0546 16.8111 11.0246 17.004C11.9945 17.197 12.9998 17.0979 13.9134 16.7195C14.827 16.3411 15.6079 15.7002 16.1574 14.8779C16.7068 14.0557 17 13.089 17 12.1001C16.9984 10.7745 16.4711 9.50366 15.5338 8.56632C14.5964 7.62898 13.3256 7.10169 12 7.1001ZM15.949 11.6001H14.289C14.2665 10.5159 14.0381 9.44596 13.616 8.4471C14.2439 8.72702 14.7889 9.16436 15.1982 9.71676C15.6074 10.2692 15.8671 10.9179 15.952 11.6001H15.949ZM12 16.1001C11.52 16.1001 10.82 14.7171 10.72 12.6001H13.28C13.183 14.7211 12.483 16.1001 12 16.1001ZM10.72 11.6001C10.82 9.4821 11.52 8.1001 12 8.1001C12.48 8.1001 13.18 9.4821 13.28 11.6001H10.72ZM10.385 8.4471C9.9636 9.4461 9.73589 10.5161 9.714 11.6001H8.053C8.13857 10.9187 8.39842 10.2709 8.80745 9.71932C9.21648 9.1677 9.76086 8.73089 10.388 8.4511L10.385 8.4471ZM8.053 12.6001H9.714C9.73695 13.684 9.96567 14.7536 10.388 15.7521C9.76046 15.472 9.21582 15.0348 8.80677 14.4826C8.39771 13.9304 8.13808 13.282 8.053 12.6001ZM13.619 15.7521C14.041 14.7536 14.2694 13.6839 14.292 12.6001H15.952C15.868 13.2826 15.609 13.9317 15.2002 14.4847C14.7915 15.0376 14.2468 15.4756 13.619 15.7561V15.7521Z"
        fill={color || colors.gray[800]}
      />
    </Svg>
  );
}
