import { LegacyRef, memo, useCallback, useEffect, useMemo, useRef } from "react";
import { StyleProp, View, ViewStyle, TouchableOpacity, Text } from "react-native";

import { useHover } from "src/foundations/hooks/useHover";
import { tailwind } from "src/foundations/styles";
import { Checkbox, Paragraph } from "src/foundations/ui";

import { TableColProp, TableRowProp } from ".";
import TableCell from "./TableCell";

type TableRowProps<T extends TableRowProp> = {
  row?: T;
  cols: TableColProp[];
  style?: StyleProp<ViewStyle>;
  isHeader?: boolean;
  rowId?: number; // Unique id for row to be passed back with callbacks
  // Selectable props
  selectable?: boolean; // Whether or not this row can be selected using a checkbox
  selected?: boolean; // Whether or not this row should show selected styles
  selectDisabled?: boolean;
  onSelectChange?: (row?: T) => void;
  // Hover props (for selectable rows)
  hoveredOverride?: boolean; // Force show hovered styles
  onHoverChange?: (hovered: boolean, rowId: number | undefined) => void;
  // Checkbox props
  checkboxAccessibilityLabel?: string;
  testID?: string;
  onRowPress?: (row: T) => void;
};

function TableRow<T extends TableRowProp>({
  row,
  style,
  isHeader,
  cols,
  rowId,
  selectable,
  selected,
  selectDisabled,
  onSelectChange,
  hoveredOverride,
  onHoverChange,
  checkboxAccessibilityLabel,
  testID,
  onRowPress,
}: TableRowProps<T>) {
  const [ref, hovered] = useHover();
  const hasMounted = useRef<boolean>(false);

  useEffect(() => {
    if (!hasMounted.current) {
      // Do not trigger hover changes for initial state
      hasMounted.current = true;
      return;
    }
    if (onHoverChange && typeof hovered === "boolean") {
      onHoverChange(hovered, rowId);
    }
  }, [hovered, onHoverChange, rowId]);

  const onCheckboxChange = () => {
    if (onSelectChange) {
      onSelectChange(row);
    }
  };

  const getStyle = () => {
    if (isHeader) {
      // Headers should have no special styles
      return style;
    }
    if (selected) {
      return tailwind("bg-green-lighter bg-opacity-40");
    }
    if (hoveredOverride || (selectable && hovered)) {
      return tailwind("bg-gray-50");
    }
    return style;
  };

  const children = useMemo(
    () =>
      isHeader
        ? cols.map((col, colIndex) => (
            <TableCell
              key={colIndex}
              style={getCellStyles(colIndex, selectable, col.width)}
              accessibilityRole="columnheader"
            >
              <Text style={tailwind("font-bold font-inter-semibold text-xs text-gray-800_60")}>
                {col.name}
              </Text>
            </TableCell>
          ))
        : cols.map((col, colIndex) => {
            const value = row?.[col.field];

            return (
              <TableCell
                key={`${rowId} ${colIndex}`}
                style={getCellStyles(colIndex, selectable, col.width)}
                testID={`${col.field}Row${rowId}`}
              >
                {value && typeof value === "object" && "Component" in value && value.Component ? (
                  typeof value.Component === "function" ? (
                    value.Component({ rowIsHovered: hoveredOverride })
                  ) : (
                    value.Component
                  )
                ) : (
                  <Paragraph
                    level={2}
                    style={col.textStyle ? col.textStyle : {}}
                    // TODO: number of lines default to 1 instead of 2
                    numberOfLines={1}
                  >
                    {value || "-"}
                  </Paragraph>
                )}
              </TableCell>
            );
          }),
    [cols, hoveredOverride, selected, selectable, row, rowId, isHeader]
  );

  const handleRowPress = useCallback(
    () => onRowPress && row !== undefined && onRowPress(row),
    [row, onRowPress]
  );

  return onRowPress ? (
    <TouchableOpacity
      style={[style, getStyle()]}
      accessibilityRole="row"
      ref={ref as LegacyRef<TouchableOpacity>}
      testID={testID}
      onPress={handleRowPress}
    >
      {selectable && (
        <TableCell style={tailwind("ml-6 mr-3")}>
          <Checkbox
            checked={selected}
            onChange={onCheckboxChange}
            label={checkboxAccessibilityLabel || "Select row"}
            visibleLabel={false}
            disabled={selectDisabled}
          />
        </TableCell>
      )}
      {children}
    </TouchableOpacity>
  ) : (
    <View
      // TODO: added position relative
      style={[style, tailwind("relative"), getStyle()]}
      accessibilityRole="row"
      ref={ref as LegacyRef<View>}
      testID={testID}
    >
      {selectable && (
        <TableCell style={tailwind("ml-6 mr-3")}>
          <Checkbox
            checked={selected}
            onChange={onCheckboxChange}
            label={checkboxAccessibilityLabel || "Select row"}
            visibleLabel={false}
            disabled={selectDisabled}
          />
        </TableCell>
      )}
      {children}
    </View>
  );
}

export default memo(TableRow) as typeof TableRow;

// TODO: change default width to 152
const CELL_MIN_WIDTH = 152;
const getCellStyles = (
  colIndex: number,
  hasSelectableRows: boolean | undefined,
  width: number | undefined
) => {
  // TODO: honor the fixed with for each column
  const flex = width === undefined ? "flex-1" : "";
  // TODO: honor the fixed width for each column
  // const widthStyle = { width: width && width > CELL_MIN_WIDTH ? width : CELL_MIN_WIDTH };
  const widthStyle = { width: width ?? CELL_MIN_WIDTH };
  if (!hasSelectableRows && colIndex === 0) {
    // If rows are not selectable, and this is the first column, use a larger
    // left margin since there will not be a preceding checkbox cell
    // TODO: honor the fixed with for each column
    return [tailwind(`${flex} ml-6 mr-3`), widthStyle];
  }
  // TODO: honor the fixed with for each column
  return [tailwind(`${flex} mx-3`), widthStyle];
};
