// TODO: Add this icon in foundations.
import Svg, { Path, G } from "svgs";

import colors from "../colors.json";

import { IconProps, DISABLED_COLOR } from "./";

export default function CalendarIcon(props: IconProps) {
  const color = props.disabled ? DISABLED_COLOR : props.color;

  return (
    <Svg height={props.size} width={props.size} viewBox="0 0 24 24">
      <G
        fill="none"
        stroke={color ?? colors.gray[800]}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <Path d="m5 9.5h14" />
        <Path d="m6.974 4.5h-2.694c-1.05.05-1.85.95-1.79 2v12c-.06 1.04.74 1.94 1.78 2h15.422c1.04-.06 1.84-.96 1.78-2v-12c.05-1.05-.75-1.95-1.79-2h-1.22" />
        <Path d="m16.5 3v3" />
        <Path d="m7.5 3v3" />
        <Path d="m9.5 4.5 6.96-.01" />
        <Path d="m7.75 13c0 .13-.12.25-.25.25-.14 0-.25-.12-.25-.25" />
        <Path d="m7.25 13c0-.14.11-.25.25-.25.13 0 .25.11.25.25" />
        <Path d="m12.25 13c0 .13-.12.25-.25.25-.14 0-.25-.12-.25-.25" />
        <Path d="m11.75 13c0-.14.11-.25.25-.25.13 0 .25.11.25.25" />
        <Path d="m16.75 13c0 .13-.12.25-.25.25-.14 0-.25-.12-.25-.25" />
        <Path d="m16.25 13c0-.14.11-.25.25-.25.13 0 .25.11.25.25" />
        <Path d="m7.75 17c0 .13-.12.25-.25.25-.14 0-.25-.12-.25-.25" />
        <Path d="m7.25 17c0-.14.11-.25.25-.25.13 0 .25.11.25.25" />
        <Path d="m12.25 17c0 .13-.12.25-.25.25-.14 0-.25-.12-.25-.25" />
        <Path d="m11.75 17c0-.14.11-.25.25-.25.13 0 .25.11.25.25" />
        <Path d="m16.75 17c0 .13-.12.25-.25.25-.14 0-.25-.12-.25-.25" />
        <Path d="m16.25 17c0-.14.11-.25.25-.25.13 0 .25.11.25.25" />
      </G>
    </Svg>
  );
}
