import "react-native-toast-notifications";

import { tailwind } from "./styles";
import ToastNotificationTile from "./ui/ToastNotificationTile";

type ToastProps = {
  message: string | JSX.Element;
};

// TODO: should type this based on the library so we do not get issues with type being incorrect
type ToastProviderProps = {
  placement?: "top" | "bottom" | "bottom-right";
  animationType?: "slide-in" | "zoom-in";
  offset?: number;
  offsetRight?: number;
  renderType?: {
    [type: string]: (toast: ToastProps) => JSX.Element;
  };
};

const toastOptions: ToastProviderProps = {
  placement: "bottom-right",
  animationType: "slide-in",
  offset: 28,
  offsetRight: 28,
  renderType: {
    success: (toast: ToastProps) => (
      <ToastNotificationTile
        title="Success"
        message={toast.message as string}
        messageType="success"
        // There's already a p-2 on the bottom of the toast for the shadow,
        // so combined, this should add up to the correct spacing of 16px
        style={tailwind("mt-2")}
      />
    ),
    failure: (toast: ToastProps) => (
      <ToastNotificationTile
        title="There was a problem"
        message={toast.message as string}
        messageType="failure"
        // There's already a p-2 on the bottom of the toast for the shadow,
        // so combined, this should add up to the correct spacing of 16px
        style={tailwind("mt-2")}
      />
    ),
  },
};

export default toastOptions;
