import { AUTH_TOKEN_STORAGE_KEY, CURRENT_USER_ID_STORAGE_KEY } from "src/auth/constants";
import { UUID } from "src/common/types/primitives";

export type Tokens = {
  [userId: string]: string | null;
};

let sessionTokens: Tokens = {};
let currentUserId: UUID | null | undefined = null;

export const saveCurrentUserId = (userId: UUID): void => {
  localStorage.setItem(CURRENT_USER_ID_STORAGE_KEY, userId);
  getCurrentUserId();
};

export const getCurrentUserId: () => string | null = () => {
  const userId = localStorage.getItem(CURRENT_USER_ID_STORAGE_KEY);
  currentUserId = userId;
  return userId;
};

const removeCurrentUserId = () => {
  localStorage.removeItem(CURRENT_USER_ID_STORAGE_KEY);
  getCurrentUserId();
};

export const clearCurrentUserToken = (): void => {
  const currentUserId = getCurrentUserId();
  removeToken(currentUserId);
  removeCurrentUserId();
};

export const getTokens = (): Tokens => {
  const tokens = localStorage.getItem(AUTH_TOKEN_STORAGE_KEY);
  const xTokens = tokens ? (JSON.parse(tokens) as Tokens) : {};
  sessionTokens = xTokens;
  return xTokens;
};

export const setTokens = (userId: UUID, token: string): void => {
  const tokens = getTokens();
  localStorage.setItem(AUTH_TOKEN_STORAGE_KEY, JSON.stringify({ ...tokens, [userId]: token }));
  getTokens();
  saveCurrentUserId(userId);
};

const removeToken = (userId: UUID | null) => {
  if (!userId) return;
  const tokens = getTokens();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { [userId]: removedUserToken, ...remainingTokens } = tokens;
  localStorage.setItem(AUTH_TOKEN_STORAGE_KEY, JSON.stringify({ ...remainingTokens }));
  getTokens();
};

export const getTokenForCurrentUser = (): string | null => {
  if (currentUserId && sessionTokens[currentUserId]) {
    return sessionTokens[currentUserId];
  }

  const userId = getCurrentUserId();
  const tokens = getTokens();

  return userId ? tokens[userId] : null;
};
