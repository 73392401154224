import React, { useEffect } from "react";

import Popover from "src/ui/components/Popover";
import { PopoverProps } from "src/ui/components/Popover/Popover";

import MenuItem from "./MenuItem";
import MenuList from "./MenuList";

export type MenuProps = PopoverProps;

export const Menu: React.FC<MenuProps> = (props) => {
  const { children, ...popoverProps } = props;

  useEffect(() => {
    if (process.env.NODE_ENV === "production") return;

    let showWarning = false;

    React.Children.forEach(props.children, (child) => {
      if (!React.isValidElement(child)) return;

      if (child.type !== MenuItem) {
        showWarning = true;
      }
    });

    if (showWarning) {
      console.warn(
        "Menu only supports MenuItem as children right now. Other element can be used, but make sure to follow accessibility spec for menu and menuitem."
      );
    }
    // Just display running once per component instance.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Popover {...popoverProps}>
      <MenuList>{children}</MenuList>
    </Popover>
  );
};

export default Menu;
