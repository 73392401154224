import { create } from "tailwind-rn";

import styles from "./styles.json";

const extraStyles = {
  "border-top-shadow": {
    borderTop: "1px solid rgba(0, 0, 0, 0.15)",
  },
  "border-right-shadow": {
    borderRight: "1px solid rgba(0, 0, 0, 0.15)",
  },
  "font-campton-bold": {
    fontFamily: "campton-bold",
  },
  "font-campton": {
    fontFamily: "campton-medium",
  },
  "font-campton-light": {
    fontFamily: "campton-light",
  },
  "font-inter": {
    fontFamily: "inter",
  },
  "font-inter-semibold": {
    fontFamily: "inter-semibold",
  },
  shadow: {
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 1 },
  },
  "shadow-size-s": {
    shadowOpacity: 0.15,
    shadowRadius: 4,
    elevation: 2,
  },
  "shadow-size-m-light": {
    shadowOpacity: 0.08,
    shadowRadius: 6,
    elevation: 5,
  },
  "shadow-size-m": {
    shadowOpacity: 0.25,
    shadowRadius: 6,
    elevation: 5,
  },
};

const { tailwind, getColor } = create({ ...styles, ...extraStyles });
export { tailwind, getColor };
