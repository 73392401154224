import { StyleProp, View, ViewStyle } from "react-native";

import { tailwind } from "../styles";

import { Icon, Tile, Paragraph } from ".";

// Temporarily needed in the toastOptions to know how far to render from bottom
export const TOAST_TILE_HEIGHT = 76;

type ToastNotificationProps = {
  title: string;
  message: string;
  messageType: "success" | "failure";
  style?: StyleProp<ViewStyle>;
};

function MessageStatus(props: Pick<ToastNotificationProps, "messageType">) {
  switch (props.messageType) {
    case "success":
      return (
        <View style={tailwind("bg-green p-5 h-full justify-center")}>
          <Icon name="success" size={32} color={"white"} />
        </View>
      );
    case "failure":
    default:
      return (
        <View style={tailwind("bg-red p-5 h-full justify-center")}>
          <Icon name="failure" size={32} color={"white"} />
        </View>
      );
  }
}

export default function ToastNotificationTile(props: ToastNotificationProps) {
  return (
    // wrap the tile in a slightly larger view, just to make sure the shadow isn't
    // getting cut off when rendered by react-native-toast-notifications
    <View
      style={[tailwind("justify-center items-center pb-1 pr-1"), props.style]}
      testID={`toast-${props.messageType}`}
    >
      <Tile
        style={[tailwind("rounded-2xl overflow-hidden"), { width: 456, height: TOAST_TILE_HEIGHT }]}
      >
        <MessageStatus messageType={props.messageType} />
        <View style={tailwind("p-4")}>
          <Paragraph
            level={2}
            weight="bold"
            accessibilityRole="label"
            style={[tailwind("text-gray-600"), { width: 300 }]}
            numberOfLines={1}
          >
            {props.title}
          </Paragraph>
          <Paragraph
            level={2}
            style={[tailwind("text-gray-600"), { width: 300 }]}
            numberOfLines={2}
          >
            {props.message}
          </Paragraph>
        </View>
      </Tile>
    </View>
  );
}
