import * as React from "react";
import Svg, { Path } from "svgs";

import colors from "../colors.json";

import { IconProps, DISABLED_COLOR } from ".";

export default function DocumentIcon(props: IconProps) {
  const color = props.disabled ? DISABLED_COLOR : props.color;
  return (
    <Svg width={props.size} height={props.size} viewBox="0 0 24 24" fill="none">
      <Path
        d="M16.5 2.09998H5.5C5.10218 2.09998 4.72064 2.25801 4.43934 2.53932C4.15804 2.82062 4 3.20215 4 3.59998V20.6C4 20.9978 4.15804 21.3793 4.43934 21.6606C4.72064 21.9419 5.10218 22.1 5.5 22.1H16.5C17.4283 22.1 18.3185 21.7312 18.9749 21.0748C19.6313 20.4185 20 19.5282 20 18.6V5.59998C20 4.67172 19.6313 3.78148 18.9749 3.1251C18.3185 2.46872 17.4283 2.09998 16.5 2.09998ZM19 18.6C19 19.263 18.7366 19.8989 18.2678 20.3677C17.7989 20.8366 17.163 21.1 16.5 21.1H5.5C5.36739 21.1 5.24021 21.0473 5.14645 20.9535C5.05268 20.8598 5 20.7326 5 20.6V3.59998C5 3.46737 5.05268 3.34019 5.14645 3.24642C5.24021 3.15265 5.36739 3.09998 5.5 3.09998H16.5C17.163 3.09998 17.7989 3.36337 18.2678 3.83221C18.7366 4.30105 19 4.93693 19 5.59998V18.6Z"
        fill={color || colors.green.DEFAULT}
      />
      <Path
        d="M7.5 9H16C16.1326 9 16.2598 8.94732 16.3536 8.85355C16.4473 8.75979 16.5 8.63261 16.5 8.5C16.5 8.36739 16.4473 8.24021 16.3536 8.14645C16.2598 8.05268 16.1326 8 16 8H7.5C7.36739 8 7.24021 8.05268 7.14645 8.14645C7.05268 8.24021 7 8.36739 7 8.5C7 8.63261 7.05268 8.75979 7.14645 8.85355C7.24021 8.94732 7.36739 9 7.5 9Z"
        fill={color || colors.green.DEFAULT}
      />
      <Path
        d="M7.5 12H16C16.1326 12 16.2598 11.9473 16.3536 11.8536C16.4473 11.7598 16.5 11.6326 16.5 11.5C16.5 11.3674 16.4473 11.2402 16.3536 11.1464C16.2598 11.0527 16.1326 11 16 11H7.5C7.36739 11 7.24021 11.0527 7.14645 11.1464C7.05268 11.2402 7 11.3674 7 11.5C7 11.6326 7.05268 11.7598 7.14645 11.8536C7.24021 11.9473 7.36739 12 7.5 12Z"
        fill={color || colors.green.DEFAULT}
      />
      <Path
        d="M7.5 15H16C16.1326 15 16.2598 14.9473 16.3536 14.8536C16.4473 14.7598 16.5 14.6326 16.5 14.5C16.5 14.3674 16.4473 14.2402 16.3536 14.1464C16.2598 14.0527 16.1326 14 16 14H7.5C7.36739 14 7.24021 14.0527 7.14645 14.1464C7.05268 14.2402 7 14.3674 7 14.5C7 14.6326 7.05268 14.7598 7.14645 14.8536C7.24021 14.9473 7.36739 15 7.5 15Z"
        fill={color || colors.green.DEFAULT}
      />
      <Path
        d="M7.5 18H15C15.1326 18 15.2598 17.9473 15.3536 17.8536C15.4473 17.7598 15.5 17.6326 15.5 17.5C15.5 17.3674 15.4473 17.2402 15.3536 17.1464C15.2598 17.0527 15.1326 17 15 17H7.5C7.36739 17 7.24021 17.0527 7.14645 17.1464C7.05268 17.2402 7 17.3674 7 17.5C7 17.6326 7.05268 17.7598 7.14645 17.8536C7.24021 17.9473 7.36739 18 7.5 18Z"
        fill={color || colors.green.DEFAULT}
      />
    </Svg>
  );
}
