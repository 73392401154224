import React from "react";
import { View } from "react-native";

import { tailwind } from "../styles";

import { Subtitle, Paragraph } from ".";

type State = {
  hasError: boolean;
};

type Props = {
  children: React.ReactNode;
};
export default class PageErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      // Render fallback UI
      return (
        <View style={tailwind("w-full h-full flex-col justify-center items-center")}>
          <View style={tailwind("w-2/3 h-1/2 bg-gray-50")}></View>
          <Subtitle weight="bold" style={tailwind("mt-8")}>
            Oops!
          </Subtitle>
          <Paragraph style={tailwind("mt-2")}>An error occurred when loading this page.</Paragraph>
          <Paragraph>Try reloading the page or signing in again.</Paragraph>
          <Paragraph>If this problem persists, please contact support.</Paragraph>
        </View>
      );
    }

    return this.props.children;
  }
}
