import { mergeRefs } from "@react-aria/utils";
import { forwardRef, memo, useMemo, useCallback } from "react";
import { Pressable, PressableProps, PressableStateCallbackType, View } from "react-native";

import { useHover } from "src/foundations/hooks/useHover";
import { tailwind } from "src/foundations/styles";

export type PressableHighlightProps = PressableProps;

const PressableHighlight = forwardRef<View, PressableHighlightProps>(function PressableHighlight(
  props,
  forwardedRef
) {
  const [hoverRef, isHovering] = useHover<View>();
  const combinedRefs = mergeRefs(hoverRef, forwardedRef);

  const { style: externalStyle, ...rest } = props;

  const internalStyle = useMemo(() => [isHovering && tailwind("bg-gray-100")], [isHovering]);

  const getCombinedStyle = useCallback(
    (state: PressableStateCallbackType) => {
      return typeof externalStyle === "function"
        ? [externalStyle(state), internalStyle]
        : [externalStyle, internalStyle];
    },
    [externalStyle, internalStyle]
  );

  return <Pressable {...rest} ref={combinedRefs} style={getCombinedStyle} />;
});

export default memo(PressableHighlight) as typeof PressableHighlight;
