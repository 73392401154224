import { Platform } from "react-native";

import { CarbonHeaders } from "src/auth/constants";
import { getTokenForCurrentUser } from "src/auth/utils/userToken";

export function createFetcher(baseUrl: string) {
  return async function fetcher(reqInfo: RequestInfo, init: RequestInit = {}): Promise<Response> {
    const token = getTokenForCurrentUser();

    return await fetch(
      typeof reqInfo === "string"
        ? `${baseUrl}${reqInfo}`
        : { ...reqInfo, url: `${baseUrl}${reqInfo.url}` },
      {
        ...init,
        headers: {
          ...init.headers,
          "content-type": "application/json",
          [CarbonHeaders.PLATFORM_TYPE]: Platform.OS,
          [CarbonHeaders.APP_TYPE]: "billingHub",
          [CarbonHeaders.APP_VERSION]: __NEXT_DATA__.buildId,
          ...(token && { [CarbonHeaders.AUTHORIZATION]: `Bearer ${token}` }),
        },
      }
    );
  };
}

export const monolithFetcher = createFetcher(process.env.NEXT_PUBLIC_MONOLITH_URI + "/hib");
export const graphQLFetcher = createFetcher(process.env.NEXT_PUBLIC_GRAPHQL_URI || "");
