import { memo } from "react";
import Svg, { Path } from "svgs";

import colors from "../colors.json";

import { IconProps, DISABLED_COLOR } from ".";

const FileDollar: React.FC<IconProps> = (props) => {
  const color = props.disabled ? DISABLED_COLOR : props.color;
  return (
    <Svg width={props.size} height={props.size} fill="none" viewBox="0 0 20 20">
      <Path
        fill={color ?? colors.gray["800"]}
        d="M10 11.88c.5 0 .75-.15.87-.27a.52.52 0 0 0 .17-.36c0-.1-.04-.24-.17-.36-.12-.12-.37-.26-.87-.26-.76 0-1.34-.24-1.74-.62a1.77 1.77 0 0 1-.55-1.26c0-.41.17-.89.55-1.26.28-.27.66-.46 1.12-.56v-.26a.62.62 0 1 1 1.24 0v.27c.58.12 1 .4 1.28.68a.62.62 0 1 1-.89.87A1.38 1.38 0 0 0 10 8.13c-.5 0-.75.14-.87.26a.53.53 0 0 0-.17.36c0 .08.04.23.17.36.12.12.38.27.87.27.75 0 1.33.22 1.73.6.4.37.56.85.56 1.27 0 .42-.17.9-.56 1.27-.28.27-.65.46-1.1.55v.26a.62.62 0 1 1-1.26 0v-.27a2.32 2.32 0 0 1-1.6-1.11.62.62 0 0 1 1.12-.56c.04.07.36.49 1.11.49Z"
      />
      <Path
        fill={color ?? colors.gray["800"]}
        fillRule="evenodd"
        d="M4.17 2.7c-.8 0-1.46.66-1.46 1.47v11.66c0 .8.65 1.46 1.46 1.46h11.66c.8 0 1.46-.65 1.46-1.46V7.01c0-.38-.15-.76-.43-1.03l-2.84-2.84a1.46 1.46 0 0 0-1.03-.43H4.17Zm-.21 1.47c0-.12.1-.21.2-.21H13c.05 0 .1.02.15.06l2.84 2.84c.04.04.06.1.06.15v8.82c0 .12-.1.21-.2.21H4.16a.2.2 0 0 1-.21-.2V4.16Z"
        clipRule="evenodd"
      />
    </Svg>
  );
};

export default memo(FileDollar) as typeof FileDollar;
