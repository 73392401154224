import { memo } from "react";
import { StyleProp, View, ViewStyle } from "react-native";

type TableCellProps = {
  style?: StyleProp<ViewStyle>;
  children?: React.ReactNode;
  accessibilityRole?: "columnheader" | "cell";
  testID?: string;
};

function TableCell({ style, children, accessibilityRole = "cell", testID }: TableCellProps) {
  return (
    <View style={style} accessibilityRole={accessibilityRole} testID={testID}>
      {children}
    </View>
  );
}

export default memo(TableCell);
