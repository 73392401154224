import { gql, ApolloClient } from "@apollo/client";
import { keys, intersection, merge, flatten } from "lodash";
import React, { createContext, useState, useEffect, useContext, useRef } from "react";

import { batch, createIntervalScheduler, AddToBatch } from "../../common/utils/batch";
import { isDefined } from "../utils/truthy";

import {
  BillingHubDashboardFeatureFlagsQuery,
  BillingHubDashboardFeatureFlagsQueryVariables,
} from "./__generated__/useFeatureFlag.type";

type FeatureFlagClientContext = {
  fetchFlags?: AddToBatch<unknown, FeatureFlagRequest>;
};

// we use this for the hook, but not for the graphql request
type AttributeMap = {
  [x: string]: string;
};

type FeatureFlagBulkRequest = {
  attributes: AttributeMap;
  anonymous?: boolean;
  keys: Array<string>;
};

type FeatureFlagRequest = {
  attributes: AttributeMap;
  anonymous?: boolean;
  key: string;
};

export const FEATURE_FLAG_QUERY = gql`
  query BillingHubDashboardFeatureFlags(
    $keys: [String!]!
    $anonymous: Boolean
    $attributes: [FeatureFlagAttributeInput!]
  ) {
    featureFlags(keys: $keys, anonymous: $anonymous, attributes: $attributes) {
      key
      value
    }
  }
`;

export const FeatureFlagContext = createContext(Object.create(null) as FeatureFlagClientContext);

export function FeatureFlagProvider<T>({
  client,
  children,
}: {
  client: ApolloClient<T>;
  children: React.ReactNode;
}) {
  const fetchFlags = batch<unknown | undefined, FeatureFlagRequest>((requests) => {
    const chunks: [FeatureFlagBulkRequest] = [
      {
        keys: [],
        attributes: {},
      },
    ];

    /**
     *  In order to prevent collisions from creating unexpected behavior, we ensure that
     *  no feature flags batched together overwrite each others override attributes
     */
    function mergeOrCreateChunk(request: FeatureFlagRequest, chunkIndex = 0) {
      const chunk = chunks[chunkIndex];
      // if there are custom attributes, see if there are any collisions
      const overrideAttributes = keys(chunk.attributes);
      const customAttributes = keys(request.attributes);

      // we check to see 1) if there are any keys that overlap and 2) if they have different values
      // to determine collisions
      const collisions = intersection(overrideAttributes, customAttributes).filter((key) => {
        return chunk.attributes[key] !== request.attributes[key];
      });

      // if we don't have any collisions, add this to the current chunk and merge the attributes
      if (collisions.length === 0) {
        // if the key is already in the chunk, we don't need to merge because
        // they're the same request
        if (!chunk.keys.includes(request.key)) {
          chunk.keys.push(request.key);
          merge(chunk.attributes, request.attributes);
        }
        return;
      }

      // if there are any collisions, we want to split this request off into a new chunk
      // we start by checking the to see if there is a next chunk add it if not
      if (!chunks[chunkIndex + 1]) {
        chunks.push({
          keys: [request.key],
          attributes: request.attributes,
        });
        return;
      }

      // recurse until you find or create a safe chunk for this request
      mergeOrCreateChunk(request, chunkIndex + 1);
    }

    requests.forEach((request) => mergeOrCreateChunk(request));
    // An anonymous request is not batched so it should only ever have one, otherwise
    // we assume all anonymous in the requests are the same
    const anonymous = requests[0]?.anonymous || false;

    // make a request for each chunk and merge the results
    return Promise.all(
      chunks.map((chunk) => {
        return client
          .query<
            BillingHubDashboardFeatureFlagsQuery,
            BillingHubDashboardFeatureFlagsQueryVariables
          >({
            query: FEATURE_FLAG_QUERY,
            variables: {
              keys: chunk.keys,
              attributes: chunk.attributes
                ? Object.entries(chunk.attributes).map(([key, value]) => ({ key, value }))
                : null,
              anonymous,
            },
          })
          .then(({ data }) => data.featureFlags);
      })
    )
      .then(flatten)
      .then((x) => x.filter(isDefined))
      .then((flags) =>
        requests.map((flag) => flag.key).map((key) => flags.find((f) => f.key === key)?.value)
      );
  }, createIntervalScheduler());

  return (
    <FeatureFlagContext.Provider value={{ fetchFlags }}>{children}</FeatureFlagContext.Provider>
  );
}

// This is used to make sure we don't try to setState in the hook anywhere in
// response to a promise resolution after a component has been unmounted
function useIsMountedRef() {
  const isMountedRef = useRef<boolean | null>(null);
  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  });
  return isMountedRef;
}

export function useFeatureFlag<T>(
  flagName: string,
  defaultValue: T,
  attributes?: AttributeMap,
  skip = false,
  anonymous = false
): [T | undefined, boolean] {
  const [value, setValue] = useState<T | undefined>();
  const { fetchFlags } = useContext(FeatureFlagContext);
  const isMountedRef = useIsMountedRef();

  useEffect(() => {
    if (skip || !fetchFlags) return;

    const featureFlagRequest: FeatureFlagRequest = {
      attributes: attributes || {},
      anonymous,
      key: flagName,
    };
    fetchFlags(featureFlagRequest, !anonymous)
      .then((v) => isMountedRef.current && setValue(v as T))
      .catch(() => isMountedRef.current && setValue(defaultValue));
  }, [fetchFlags, anonymous, skip, defaultValue, attributes, flagName, isMountedRef]);

  const returnValue: T | undefined = skip ? defaultValue : value;

  const loading = returnValue === undefined;

  return [returnValue, loading];
}
